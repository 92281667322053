import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseProduct = new (class extends ApiBase {
  /**
   * @param {number} productId
   * @returns {Promise}
   */
  get(productId) {
    const url = `/showcase/products/${productId}`

    return this._GET(url)
  }
})()
