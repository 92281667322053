import Constants from 'Constants'
import { isEmpty } from 'lodash'

export default {
  fullNameWithShortLastName({ profile }) {
    if (isEmpty(profile.firstName)) {
      return ''
    }

    const words = []

    const firstName =
      profile.firstName[0].toUpperCase() +
      profile.firstName.split('').slice(1).join('').toLowerCase()

    words.push(firstName)

    if (!isEmpty(profile.lastName)) {
      const shortLastName = `${profile.lastName[0].toUpperCase()}.`

      words.push(shortLastName)
    }

    return words.join(' ')
  },

  fullName({ profile }) {
    return [profile.lastName, profile.firstName, profile.middleName]
      .filter((word) => !isEmpty(word))
      .join(' ')
  },

  nameAbbreviation({ profile }) {
    return [profile.firstName, profile.lastName]
      .filter((word) => !isEmpty(word))
      .map((word) => word[0])
      .join('')
      .toUpperCase()
  },

  streamAcademicYearPreset({ profile }) {
    return profile.streamAcademicYearPreset
  },

  isDemoProfile({ profile }) {
    return Boolean(profile.isDemo)
  },

  demoAccessTime({ profile }) {
    return Number(profile.demoAccessTime)
  },

  demoMessage({ profile }) {
    const { EXTRAMURAL, FAMILY } = Constants.educationType

    switch (profile.educationType?.id) {
      case EXTRAMURAL:
        return profile.school?.demoMessage

      case FAMILY:
        return profile.school?.demoMessageFe

      default:
    }

    return ''
  },

  educationType({ profile }) {
    const { EXTRAMURAL, FAMILY, FULL_TIME } = Constants.educationType
    const id = profile.educationType?.id

    return {
      extramural: id === EXTRAMURAL,
      family: id === FAMILY,
      fullTime: id === FULL_TIME,
    }
  },

  isSchoolEducont(state) {
    return Boolean(state.profile?.school?.isEducont)
  },

  email(state) {
    return state.profile.email ?? ''
  },
}
