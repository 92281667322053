import ApiBase from 'ApiRest/ApiBase'

export default new (class News extends ApiBase {
  /**
   * Отдельная новость
   *
   * @param {number} studentId
   * @param {number} newsId
   * @returns {Promise}
   */
  get(studentId, newsId) {
    return this._GET(`/account/client/students/${studentId}/news/${newsId}`)
  }
})()
