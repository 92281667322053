export default () => ({
  notifications: [
    // {
    //   id: 1,
    //   date: '2020-09-20',
    //   text: 'Уведомление',
    //   isRead: false
    // },
  ],
})
