import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class Grades extends ApiBase {
  /**
   * @param {number} id
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(id, parameters = {}) {
    return this._GET(`/schools/${id}/grades?${UrlHelper.param(parameters)}`)
  }
})()
