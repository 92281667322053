import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.target
   * @param {number} payload.count
   */
  setNotificationsCount(state, { target, count = 0 }) {
    state.links.find((link) => link.link.name === target).notifications = count
  },
}
