const _storage = localStorage

const storageApi = {
  get(name) {
    return _storage.getItem(name)
  },
  set(name, value) {
    return _storage.setItem(name, value)
  },
  remove(name) {
    return _storage.removeItem(name)
  },
  getAll() {
    const result = {}

    for (const [key, value] of Object.entries(_storage)) {
      result[key] = value
    }

    return result
  },
  removeAll() {
    return _storage.clear()
  },
}

export default storageApi
