import Constants from 'Constants'
import { cloneDeep } from 'lodash'

export default {
  clientKinshipDegree(state) {
    return state.contractSimplified.kinship?.kinshipDegree
  },

  clientCitizenship(state) {
    return state.contractSimplified.client.citizenship
  },

  clientMoscowRegistrationType(state) {
    return state.contractSimplified.client.moscowRegistration?.type
  },

  clientPassportSeries(state) {
    return state.contractSimplified.client.passport?.series
  },

  clientPassportNumber(state) {
    return state.contractSimplified.client.passport?.number
  },

  clientPassportIssuedBy(state) {
    return state.contractSimplified.client.passport?.issuedBy
  },

  clientPassportIssueDate(state) {
    return state.contractSimplified.client.passport?.issueDate
  },

  clientPassportValidUntil(state) {
    return state.contractSimplified.client.passport?.validUntil
  },

  clientPassportDepartmentCode(state) {
    return state.contractSimplified.client.passport?.departmentCode
  },

  clientPassportCountry(state) {
    return state.contractSimplified.client.passport?.country
  },

  clientAddressResidenceMatchesTemporary(state) {
    return (
      state.contractSimplified.client.address?.residenceMatchesTemporary ??
      false
    )
  },

  clientAddressResidenceMatchesPermanent(state) {
    return (
      state.contractSimplified.client.address?.residenceMatchesPermanent ??
      false
    )
  },

  clientAddressResidence(state) {
    return state.contractSimplified.client.address?.residence
  },

  clientAddressTemporary(state) {
    return state.contractSimplified.client.address?.temporary
  },

  clientAddressPermanent(state) {
    return state.contractSimplified.client.address?.permanent
  },

  clientAddressPermanentIndex(state) {
    return state.contractSimplified.client.address?.permanentIndex
  },

  clientAddressResidenceIndex(state) {
    return state.contractSimplified.client.address?.residenceIndex
  },

  clientAddressTemporaryIndex(state) {
    return state.contractSimplified.client.address?.temporaryIndex
  },

  clientGrade(state) {
    return state.contractSimplified.grade
  },

  clientAgreedWithPolitics(state) {
    return state.contractSimplified.agreedWithPolitics ?? false
  },

  clientAgreedWithProcessingPDExtramural(state) {
    return state.contractSimplified.agreedWithProcessingPDExtramural ?? false
  },

  getStudentsWithFullName(state, getters) {
    return cloneDeep(getters.getStudentsFilteredForRequest).map((student) => {
      let fullName = ''

      if (student.lastName) {
        fullName += student.lastName
      }

      if (student.firstName) {
        fullName += ` ${student.firstName}`
      }

      if (student.middleName) {
        fullName += ` ${student.middleName}`
      }

      return {
        ...student,
        fullName,
      }
    })
  },

  getStudentsFilteredForRequest(state, getters, rootState) {
    return rootState.client.students.students.filter(
      (student) =>
        student.isDemo ||
        (!student.hasActualEnrollmentContract &&
          !student.hasFutureEnrollmentContract &&
          student.enrollmentStatus?.id !==
            Constants.enrollmentStatus.ENROLLED) ||
        student.enrollmentStatus?.id === Constants.enrollmentStatus.EXPELLED,
    )
  },

  studentFillDataTypes(state, getters) {
    const students = getters.getStudentsWithFullName

    if (students.length) {
      return state.studentFillDataTypes
    }

    return state.studentFillDataTypes.slice(0, 1)
  },

  isEducationTypeExtramural(state) {
    return (
      state.contractSimplified.educationType?.id ===
      Constants.educationType.EXTRAMURAL
    )
  },

  studentFirstName(state) {
    return state.contractSimplified.student.firstName
  },

  studentLastName(state) {
    return state.contractSimplified.student.lastName
  },

  studentMiddleName(state) {
    return state.contractSimplified.student.middleName
  },

  studentEmail(state) {
    return state.contractSimplified.student.email
  },

  studentPhone(state) {
    return state.contractSimplified.student.phone
  },

  studentBirthDate(state) {
    return state.contractSimplified.student.birthDate
  },

  studentFillDataType(state) {
    return state.contractSimplified.studentFillDataType
  },

  existingStudent(state) {
    return state.existingStudent
  },

  allValid(state) {
    return !Object.values(state.validStatus).every((status) => status === true)
  },

  countriesFiltered(state) {
    return state.countries.filter(
      (country) => country.id !== Constants.country.RUSSIA,
    )
  },
}
