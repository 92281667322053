import { WebAnalyticsHelper } from 'Helpers/WebAnalyticsHelper'

export const family = {
  /**
   * Клиент в заявке на семейное обучение на шаге "Данные для договора"
   * нажал кнопку "Дальше"
   */
  stepContractDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataContractFamily()
  },

  /**
   * Клиент в заявке на семейное обучение на шаге "Данные клиента"
   * нажал кнопку "Дальше"
   */
  stepClientDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataClientFamily()
  },

  /**
   * Клиент в заявке на семейное обучение на шаге "Данные ученика"
   * нажал кнопку "Дальше"
   */
  stepStudentDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataStudentFamily()
  },

  /**
   * Клиент в заявке на семейное обучение на шаге "Оферта"
   * нажал кнопку "Принимаю"
   */
  stepOfferButtonAgreeClicked() {
    WebAnalyticsHelper.goalDataCheckFamily()
  },
}
