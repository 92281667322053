import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseCartCheckout = new (class extends ApiBase {
  /**
   * @param {Object[]} items
   * @param {number} items.id
   * @param {Object} items.consumer
   * @param {number} items.consumer.id
   * @returns {Promise}
   */
  post(items) {
    const url = '/cart/checkout'

    return this._POST(url, {
      items,
    })
  }
})()
