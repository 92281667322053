import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportIdeaOptionStatus = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    const url = `/support/ideas/options/statuses?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }
})()
