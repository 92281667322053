import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class List extends ApiBase {
  /**
   * Список новостей
   *
   * @param {number} studentId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(studentId, parameters = {}) {
    return this._GET(
      `/account/client/students/${studentId}/news?${UrlHelper.param(
        parameters,
      )}`,
    )
  }
})()
