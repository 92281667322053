import actions from '@frontend/store/showcase/common/catalog/actions'
import getters from '@frontend/store/showcase/common/catalog/getters'
import mutations from '@frontend/store/showcase/common/catalog/mutations'
import state from '@frontend/store/showcase/common/catalog/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
