import module from '@frontend/store/common/module/store'
import notifications from '@frontend/store/common/notifications/store'
import support from '@frontend/store/common/support/store'
import user from '@frontend/store/common/user/store'

export default {
  namespaced: true,

  modules: {
    module,
    notifications,
    support,
    user,
  },
}
