export default {
  fullNameWithShortLastName: (state) => {
    if (!state.profile.firstName) {
      return ''
    }

    const firstName =
      state.profile.firstName[0].toUpperCase() +
      state.profile.firstName.split('').slice(1).join('').toLowerCase()

    let lastName = ''

    if (state.profile.lastName) {
      lastName = `${state.profile.lastName[0].toUpperCase()}.`
    }

    return [firstName, lastName].filter(Boolean).join(' ')
  },

  fullName: (state) =>
    [state.profile.lastName, state.profile.firstName, state.profile.middleName]
      .filter(Boolean)
      .join(' '),

  nameAbbreviation: (state) =>
    [state.profile.firstName, state.profile.lastName]
      .filter(Boolean)
      .map((i) => i[0])
      .join('')
      .toUpperCase(),

  isSchoolPlatformDemoAllowed: (state) =>
    Boolean(state.schoolPlatform.isDemoAllowed) ||
    Boolean(state.schoolPlatform.isDemoAllowedFe),
}
