import { i18n } from '@/i18n/setup'

export class LocaleController {
  constructor({ institutionType, institutionId, userType, lang }) {
    this.institutionType = institutionType
    this.institutionId = institutionId
    this.userType = userType
    this.lang = lang
  }

  loadedLocales = []

  loadMessages(institutionId = 'default') {
    return import(
      `../../../node_modules/ibls-texts/src` +
        `/${this.institutionType}/${institutionId}` +
        `/${this.userType}/${this.lang}.json`
    )
      .then(({ default: messages = {} }) => messages)
      .catch(() => ({}))
  }

  get isLoadedLocale() {
    return this.loadedLocales.includes(this.localeName)
  }

  get localeName() {
    return this.institutionId ? `${this.lang}-${this.institutionId}` : this.lang
  }

  resetLangOfHTMLElement() {
    document.querySelector('html').setAttribute('lang', this.lang)
  }

  resetLocale() {
    i18n.locale = this.localeName
    i18n.fallbackLocale = this.lang

    this.resetLangOfHTMLElement()
  }

  async changeLocale() {
    if (!this.isLoadedLocale) {
      const localeByDefault = this.lang
      const messagesByDefault = await this.loadMessages()

      i18n.setLocaleMessage(localeByDefault, messagesByDefault)

      if (this.institutionId) {
        const locale = this.localeName
        const messages = await this.loadMessages(this.institutionId)

        i18n.setLocaleMessage(locale, messages)
      }

      this.loadedLocales.push(this.lang)
    }

    this.resetLocale()
  }
}
