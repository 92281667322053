import { clientMainRoutes } from '@frontend/routes/showcase/client/clientMainRoutes'
import { clientShowcaseRoutes } from '@frontend/routes/showcase/client/clientShowcaseRoutes'
import { clientSubjectRoutes } from '@frontend/routes/showcase/client/clientSubjectRoutes'
import { clientSupportRoutes } from '@frontend/routes/showcase/client/clientSupportRoutes'
import { clientTestRoutes } from '@frontend/routes/showcase/client/clientTestRoutes'
import UserStoreShowcase from '@frontend/UserStoreShowcase'

const checkRole = (to, from, next) => {
  if (UserStoreShowcase.store.getters['common/user/isClient']) {
    next()
  } else {
    next({
      name: 'guest-showcase',
    })
  }
}

export const clientRoutes = [
  {
    path: '/client',
    component: () =>
      import(
        /* webpackChunkName: "showcase-client" */
        '@frontend/layouts/showcase/ClientMain.vue'
      ),
    meta: {
      isDisabledScroll: true,
    },

    children: [
      ...clientMainRoutes,
      ...clientShowcaseRoutes,
      ...clientSubjectRoutes,
      ...clientSupportRoutes,
    ],
  },

  ...clientTestRoutes,
]

clientRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
