import { guestShowcaseRoutes } from '@frontend/routes/showcase/guest/guestShowcaseRoutes'
import UserStoreShowcase from '@frontend/UserStoreShowcase'

const checkRole = (to, from, next) => {
  if (UserStoreShowcase.store.getters['common/user/isClient']) {
    next({
      name: 'client-showcase',
    })
  } else if (UserStoreShowcase.store.getters['common/user/isStudent']) {
    next({
      name: 'student-showcase',
    })
  } else {
    next()
  }
}

export const guestRoutes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "showcase-guest" */
        '@frontend/layouts/showcase/GuestMain.vue'
      ),

    children: [...guestShowcaseRoutes],
  },
]

guestRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
