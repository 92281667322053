import { render, staticRenderFns } from "./LayoutBase.vue?vue&type=template&id=4abee880&"
import script from "./LayoutBase.vue?vue&type=script&lang=js&"
export * from "./LayoutBase.vue?vue&type=script&lang=js&"
import style0 from "./LayoutBase.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev-beta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4abee880')) {
      api.createRecord('4abee880', component.options)
    } else {
      api.reload('4abee880', component.options)
    }
    module.hot.accept("./LayoutBase.vue?vue&type=template&id=4abee880&", function () {
      api.rerender('4abee880', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "webpack/@frontend/layouts/common/main/LayoutBase.vue"
export default component.exports