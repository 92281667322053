import common from '@frontend/store/common/support/common/store'
import contract from '@frontend/store/common/support/contact/store'
import faq from '@frontend/store/common/support/faq/store'
import idea from '@frontend/store/common/support/idea/store'
import question from '@frontend/store/common/support/question/store'

export default {
  namespaced: true,

  modules: {
    common,
    contract,
    faq,
    idea,
    question,
  },
}
