import Constants from 'Constants'
import { compareAsc, compareDesc, parseISO } from 'date-fns'
import { formatToIso } from 'Helpers/dateFormatHelper'
import { cloneDeep } from 'lodash'

import { i18n } from '@/i18n/setup'

const filterTypes = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  ADDITIONAL: 'ADDITIONAL',
}

const _compareDates = (compareDirectionFunction, a, b) =>
  compareDirectionFunction(
    parseISO(formatToIso(a.date)),
    parseISO(formatToIso(b.date)),
  )

const _sortByDateAsc = (a, b) => {
  const result = _compareDates(compareAsc, a, b)

  if (result === 0) {
    return a.id - b.id
  }

  return result
}

const _sortByDateDesc = (a, b) => {
  const result = _compareDates(compareDesc, a, b)

  if (result === 0) {
    return b.id - a.id
  }

  return result
}

const _filterBySubjectTypeRequired = (subject) =>
  subject.type.id === Constants.subjectType.REQUIRED && !subject.isAdditional

const _filterBySubjectTypeOptional = (subject) =>
  subject.type.id === Constants.subjectType.OPTIONALLY && !subject.isAdditional

const _filterBySubjectTypeAdditional = (subject) => subject.isAdditional

const _filterByCtpTypeRequired = (subject) =>
  subject.ctp?.type?.id === Constants.ctpType.MAIN

const _filterByCtpTypeOptional = (subject) =>
  subject.ctp?.type?.id === Constants.ctpType.MAIN &&
  subject.type.id === Constants.subjectType.OPTIONALLY

const _filterByCtpTypeAdditional = (subject) =>
  subject.ctp?.type?.id === Constants.ctpType.ADDITIONAL

export default {
  _filterSubjects(state, getters, rootState, rootGetters) {
    return (filterType) => {
      const { fullTime: isFullTimeEducation } = rootGetters[
        'student/profile/educationType'
      ]

      switch (filterType) {
        case filterTypes.REQUIRED:
          return isFullTimeEducation
            ? _filterByCtpTypeRequired
            : _filterBySubjectTypeRequired

        case filterTypes.OPTIONAL:
          return isFullTimeEducation
            ? _filterByCtpTypeOptional
            : _filterBySubjectTypeOptional

        case filterTypes.ADDITIONAL:
          return isFullTimeEducation
            ? _filterByCtpTypeAdditional
            : _filterBySubjectTypeAdditional

        default:
          return []
      }
    }
  },

  getSubjectsRequired(state, getters) {
    return state.subjects.filter(getters._filterSubjects(filterTypes.REQUIRED))
  },

  getSubjectsOptional(state, getters) {
    return state.subjects.filter(getters._filterSubjects(filterTypes.OPTIONAL))
  },

  getSubjectsAdditional(state, getters) {
    return state.subjects.filter(
      getters._filterSubjects(filterTypes.ADDITIONAL),
    )
  },

  getSubjectsModified(state) {
    const subjects = cloneDeep(state.subjects)

    return subjects.map((subject) => {
      let subj = subject

      for (const innerSubject of state.subjects) {
        if (
          innerSubject.id === subject.id &&
          subject.isAdditional &&
          !innerSubject.isAdditional
        ) {
          subj = {
            ...subject,
            name: `${subject.name} ${i18n.t(
              'store.student.toasted_message.dop',
            )}`,
          }
          break
        }
      }

      return subj
    })
  },

  getSubjectsNames(state) {
    return state.subjects.map((subject) => subject.name)
  },

  /**
   * @param {Object} state
   * @description Поиск следующего/предыдущего по дате урока в разделе, периоде и т.д.
   * @returns {Object | undefined}
   */
  getLessonNotPassed: (state) => (id, direction = 'next') => {
    const periodsAll = cloneDeep(state.subject?.periods) || []

    for (let i = 0; i < periodsAll.length; i++) {
      const period = periodsAll[i] // Период (Четверть, Триместр и т.д.)

      for (let j = 0; j < period.sections.length; j++) {
        const section = period.sections[j] // Раздел

        for (let g = 0; g < section.lessons.length; g++) {
          const lesson = section.lessons[g] // Урок

          if (Number(lesson.id) === Number(id)) {
            let lessonsSorted

            if (direction === 'next') {
              lessonsSorted = section.lessons.sort(_sortByDateAsc)
            } else {
              lessonsSorted = section.lessons.sort(_sortByDateDesc)
            }

            const currentLessonIndex = lessonsSorted.findIndex(
              (less) => Number(less.id) === Number(id),
            )

            const targetLesson = lessonsSorted[currentLessonIndex + 1]

            if (targetLesson) {
              return targetLesson
            }

            let targetSection

            if (direction === 'next') {
              targetSection = period.sections[j + 1]
            } else {
              targetSection = period.sections[j - 1]
            }

            if (targetSection && direction === 'next') {
              return targetSection.lessons?.sort(_sortByDateAsc)[0] // Первый урок в следующем разделе
            }

            if (targetSection && direction === 'prev') {
              return targetSection.lessons?.sort(_sortByDateDesc)[0] // Последний урок в следующем разделе
            }

            let targetPeriod

            if (direction === 'next') {
              targetPeriod = periodsAll[i + 1]

              return targetPeriod?.sections[0]?.lessons?.sort(_sortByDateAsc)[0] // Первый урок в следующем периоде или undefined
            }

            targetPeriod = periodsAll[i - 1]

            return targetPeriod?.sections[0]?.lessons?.sort(_sortByDateDesc)[0] // Последний урок в следующем периоде или undefined
          }
        }
      }
    }

    return null
  },
}
