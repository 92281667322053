import { LocaleController } from 'Helpers/models/LocaleController'

export class LocaleControllerForShowcaseUser extends LocaleController {
  constructor({ lang }) {
    super({
      lang,
    })
  }

  loadMessages() {
    return import(
      `../../../node_modules/ibls-texts/src/showcase/${this.lang}.json`
    )
      .then(({ default: messages = {} }) => messages)
      .catch(() => ({}))
  }
}
