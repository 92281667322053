import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonCancel = new (class extends ApiBase {
  /**
   * Отменить запись на урок
   *
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  put(onlineLessonId) {
    return this._PUT(
      `/account/student/online-lessons/${onlineLessonId}/canceled`,
    )
  }
})()
