import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setNotifications(state, notifications) {
    state.notifications = notifications
  },
}
