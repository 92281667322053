var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LayoutBase",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("TheHeaderGuest", {
                attrs: { "school-name": _vm.schoolName },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [_c("TheFooter")]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._v(" "), _c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }