import { ApiClientJournalEvents } from 'ApiRest/Api/Account/Client/Journal/List'

export default {
  /**
   * Дневник
   *
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {Object} payload.datesRange
   * @param {string} payload.studentId
   * @returns {Promise}
   */
  async fetchJournal({ commit }, { datesRange, studentId }) {
    const { data } = await ApiClientJournalEvents.get({
      studentId,
      datesRange,
    })

    commit('setDatesRange', datesRange)
    commit('setJournal', data)
  },
}
