export default {
  eventCategories(state) {
    const categories = new Set()

    state.events.forEach((event) => {
      event.categories.forEach((cat) => {
        categories.add(cat.name)
      })
    })

    return [...categories]
  },

  eventsFiltered(state) {
    const eventTypeId = state.filter.eventType.value?.id
    const categories = state.filter.category.value

    let { events } = state

    if (eventTypeId) {
      events = state.events.filter((event) => event.type.id === eventTypeId)
    }

    if (categories.length > 0) {
      events = events.filter((event) =>
        event.categories.some((category) => categories.includes(category.name)),
      )
    }

    return events
  },
}
