export const scrollBehavior = (to, from, savedPosition) => {
  if (
    to.matched.some((route) => route.meta.isDisabledScroll) &&
    from.path === to.path
  ) {
    return {}
  }

  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    return {
      selector: to.hash,
      behavior: 'smooth',
      offset: {
        x: 0,
        y: 80,
      },
    }
  }

  return {
    x: 0,
    y: 0,
  }
}
