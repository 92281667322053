import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setInputValue(state, { name, value = '' }) {
    Vue.set(state, name, value)
  },

  setIsLoading(state, isLoading = false) {
    state.isLoading = isLoading
  },
}
