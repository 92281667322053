var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "transition",
        { attrs: { name: "fade-layout", mode: "out-in" } },
        [
          !_vm.isLoading
            ? _c("router-view", { key: "main" })
            : _c("LoaderFullScreen", { key: "loader" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }