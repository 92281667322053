export const studentTestRoutes = [
  {
    name: 'student-showcase-test-hello-ctp',
    path: '/student/test/ctp/:ctpTestId(\\d+)',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/common/showcase/test/TestHello.vue'
      ),
  },

  {
    name: 'student-showcase-test-hello-fte-ctp',
    path: '/student/test/fte-ctp/:fteCtpTestId(\\d+)',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/common/showcase/test/TestHello.vue'
      ),
  },

  {
    name: 'student-showcase-test-hello-fte-preliminary',
    path: '/student/test/fte-preliminary/:ftePreliminaryTestId(\\d+)',
    component: () =>
      import(
        /* webpackChunkName: "student-showcase-test-hello" */
        '@frontend/pages/common/showcase/test/TestHello.vue'
      ),
  },

  {
    name: 'student-showcase-test-hello-fte-final',
    path: '/student/test/fte-final/:fteFinalTestId(\\d+)',
    component: () =>
      import(
        /* webpackChunkName: "student-showcase-test-hello" */
        '@frontend/pages/common/showcase/test/TestHello.vue'
      ),
  },

  {
    name: 'student-showcase-test-preview-hello',
    path: '/student/test/preview/:testId(\\d+)/:variantNumber(\\d+)',
    component: () =>
      import(
        /* webpackChunkName: "student-showcase-test-preview-hello" */
        '@frontend/pages/common/showcase/test/TestPreviewHello.vue'
      ),
  },

  {
    name: 'student-showcase-test-preview-start',
    path: '/test/preview/:testId(\\d+)/:variantNumber(\\d+)/start',
    component: () =>
      import(
        /* webpackChunkName: "student-showcase-test-preview-start" */
        '@frontend/pages/common/showcase/test/TestPreviewStart.vue'
      ),
  },

  {
    name: 'student-showcase-test-start-ctp',
    path: '/student/test/ctp/:ctpTestId(\\d+)/start/:attemptId(\\d+)?',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/common/showcase/test/TestStart.vue'
      ),
  },
  {
    name: 'student-showcase-test-start-cert',
    path: '/student/test/cert/:certTestId(\\d+)/start',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/common/showcase/test/TestStart.vue'
      ),
  },
  {
    name: 'student-showcase-test-start-fe-cert',
    path: '/student/test/fe-cert/:feCertTestId(\\d+)/start',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/common/showcase/test/TestStart.vue'
      ),
  },
  {
    name: 'student-showcase-test-start-fte-ctp',
    path: '/student/test/fte-ctp/:fteCtpTestId(\\d+)/start',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/common/showcase/test/TestStart.vue'
      ),
  },
  {
    name: 'student-showcase-test-start-fte-preliminary',
    path: '/student/test/fte-preliminary/:ftePreliminaryTestId(\\d+)/start',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/common/showcase/test/TestStart.vue'
      ),
  },
  {
    name: 'student-showcase-test-start-fte-final',
    path: '/student/test/fte-final/:fteFinalTestId(\\d+)/start',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/common/showcase/test/TestStart.vue'
      ),
  },
]
