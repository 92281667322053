export const previewTestRoutes = [
  {
    path: '/test/preview/:testId(\\d+)/:variantNumber(\\d+)/start',
    name: 'test-preview-start',
    component: () =>
      import(
        /* webpackChunkName: "test-preview-start" */
        '@frontend/pages/student/test/TestPreviewStart.vue'
      ),
  },
  {
    path: '/test/results/preview/:testId(\\d+)/:attemptId(\\d+)',
    name: 'test-results-preview',
    component: () =>
      import(
        /* webpackChunkName: "test-preview-results" */
        '@frontend/pages/student/test/TestPreviewResults.vue'
      ),
  },
  {
    path: '/test/preview/:testId(\\d+)/:variantNumber(\\d+)',
    name: 'test-preview-hello',
    component: () =>
      import(
        /* webpackChunkName: "test-preview-hello" */
        '@frontend/pages/student/test/TestPreviewHello.vue'
      ),
  },
  {
    path: '/test/question/preview/:questionId(\\d+)',
    name: 'test-question-preview',
    component: () =>
      import(
        /* webpackChunkName: "test-question-preview" */
        '@frontend/pages/student/test/TestQuestionPreview.vue'
      ),
  },
]
