export default () => ({
  // Класс
  grades: [],
  childMoscowRegistrationTypes: [],
  kinshipDegrees: [],

  // Ответы для отправки на сервер.
  formData: {
    schoolId: null,
    clientEmail: '',
    clientPhone: '',
    clientFirstName: '',
    clientLastName: '',
    clientSecondName: '',
    clientKinshipDegreeId: null,
    studentGradeId: null,
    studentFirstName: '',
    studentLastName: '',
    studentSecondName: '',
    studentMoscowRegistrationTypeId: null,
    agree: false,
    studentBirthCertificateBase64: null,
  },

  // Meta
  meta: {
    // names читаемые наименование полей для вывода ошибок
    formData: {
      schoolId: 'Школа',
      clientEmail: 'Email родителя',
      clientPhone: 'Телефон родителя',
      clientFirstName: 'Имя родителя',
      clientLastName: 'Фамилия родителя',
      clientSecondName: 'Отчество родителя',
      clientKinshipDegreeId: 'Степень родства',
      studentGradeId: 'Класс',
      studentFirstName: 'Имя ученика',
      studentLastName: 'Фамилия ученика',
      studentSecondName: 'Отчество ученика',
      studentMoscowRegistrationTypeId: 'Московская прописка',
      agree: 'Согласие политики конфиденциальности',
      studentBirthCertificateBase64: 'Свидетельство о рождении',
    },
  },
})
