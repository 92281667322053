import ApiClientTheInvoice from 'ApiRest/Api/Account/Client/Invoice'
import ApiCancelDiscount from 'ApiRest/Api/Account/Client/Invoice/CancelDiscount'
import ApiPaymentUrl from 'ApiRest/Api/Account/Client/Invoice/PaymentUrl'
import { ApiShowcaseInvoices } from 'ApiRest/Api/Showcase/Invoices/Invoices'

export default {
  async fetchInvoices({ commit }) {
    const params = {
      showcase: 1,
    }

    const { data } = await ApiShowcaseInvoices.get(params)

    commit('setInvoices', data)
  },

  async fetchTheInvoice({ commit }, invoiceId) {
    const { data } = await ApiClientTheInvoice.get(invoiceId)

    commit('setTheInvoice', data)
  },

  /**
   * Ссылка на url счета
   *
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoicePaymentUrl({ commit }, invoiceId) {
    const { data } = await ApiPaymentUrl.get(invoiceId)

    commit('setTheInvoicePaymentUrl', data)
  },

  /**
   * Применить промокод
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.invoiceId
   * @param {Object} payload.data
   * @returns {Promise<void>}
   */
  async applyPromocode(context, { invoiceId, data }) {
    await ApiClientTheInvoice.put(invoiceId, data)
  },

  /**
   * Отменить промокод
   *
   * @param {{}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async discardPromocode(context, invoiceId) {
    await ApiCancelDiscount.put(invoiceId)
  },
}
