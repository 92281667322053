var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "home__subject", attrs: { to: _vm.subjectDetailUrl } },
    [
      _vm.educationType.fullTime
        ? _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "subject__name col-8" }, [
              _c("div", [
                _vm._v("\n        " + _vm._s(_vm.name) + "\n        "),
                _vm.subtitle
                  ? _c("span", { staticClass: "t-tag" }, [
                      _vm._v(_vm._s(_vm.subtitle)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasTrainingPrograms
                  ? _c("span", { staticClass: "t-tag" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.trainingPrograms) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isVerified
                ? _c("div", [
                    _c("img", {
                      staticClass: "subject__icon-educont-verified",
                      attrs: {
                        src: _vm.iconEducontVerified,
                        alt: "educont-verified",
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isVerified
                ? _c("div", [
                    _c("img", {
                      staticClass: "subject__icon-educont-verified",
                      attrs: {
                        src: _vm.iconEducontVerified,
                        alt: "educont-verified",
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subject__name col-4 text-right" }, [
              _vm._v(_vm._s(_vm.mark)),
            ]),
          ])
        : _c("div", { staticClass: "subject__name" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-between",
              },
              [
                _c("div", { staticClass: "flex-1" }, [
                  _vm._v("\n        " + _vm._s(_vm.name) + "\n        "),
                  _vm.subtitle
                    ? _c("span", { staticClass: "t-tag" }, [
                        _vm._v(_vm._s(_vm.subtitle)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasTrainingPrograms
                    ? _c("span", { staticClass: "t-tag" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.trainingPrograms) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.isVerified
                  ? _c("div", [
                      _c("img", {
                        staticClass: "subject__icon-educont-verified",
                        attrs: {
                          src: _vm.iconEducontVerified,
                          alt: "educont-verified",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.isAdditional
              ? _c("div", { staticClass: "t-tag" }, [_vm._v(_vm._s(_vm.grade))])
              : _vm._e(),
          ]),
      _vm._v(" "),
      !_vm.educationType.fullTime
        ? _c("ProgressBar", {
            attrs: {
              color: _vm.color,
              progress: _vm.progress,
              checkpoint: _vm.checkpoint,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.educationType.family
        ? _c("div", { staticClass: "home__subject-stat row" }, [
            _c("div", { staticClass: "col-3" }, [
              _c("span", { staticClass: "t-tag" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.subjects.topic")) +
                    "\n      "
                ),
              ]),
              _vm._v("\n\n      " + _vm._s(_vm.topicsProgress) + "\n    "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _c("span", { staticClass: "t-tag" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.subjects.development")) +
                    "\n      "
                ),
              ]),
              _vm._v("\n\n      " + _vm._s(_vm.miniTestsProgress) + "\n    "),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }