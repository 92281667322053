import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiShowcaseOnlineLesson = new (class OnlineLesson extends ApiBase {
  /**
   * @param {number} onlineLessonId
   * @param {Object} params
   * @returns {Promise}
   */
  get(onlineLessonId, params) {
    const url = `/showcase/online-lessons/${onlineLessonId}?${UrlHelper.param(
      params,
    )}`

    return this._GET(url)
  }
})()
