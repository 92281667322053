import ApiBase from 'ApiRest/ApiBase'

export const ApiIsAuthorized = new (class extends ApiBase {
  /**
   * Проверка авторизации пользователя
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload = {}) {
    return this._POST('/auth/is-authorized', payload)
  }
})()
