import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Создать Прохождение Теста для Очного КТП-Теста
   *
   * @param {number} fteCtpTestId
   * @returns {Promise}
   */
  post(fteCtpTestId) {
    return this._POST(
      `/account/student/test/lesson-tests/${fteCtpTestId}/testings`,
    )
  }
})()
