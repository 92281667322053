import ApiSubjectActive from 'ApiRest/Api/Account/Student/Subject/Active'
import ApiSubjectCtps from 'ApiRest/Api/Account/Student/Subject/Ctps'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  fetchSubjects({ commit }) {
    return ApiSubjectActive.get().then(({ data }) => {
      commit('setSubjects', data)
    })
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {number} payload.subjectId
   * @param {number} payload.groupId
   * @returns {Promise<?>}
   */
  fetchSubjectDetail({ commit }, { subjectId, groupId }) {
    return ApiSubjectCtps.get(subjectId, groupId).then(({ data }) => {
      commit('setSubjectDetail', data)
    })
  },

  resetState({ commit }) {
    commit('resetState')
  },
}
