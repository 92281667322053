import { mainTabsStudent } from '@frontend/helpers/mainTabs'

const initialTab = mainTabsStudent[Object.keys(mainTabsStudent)[0]].id

/**
 * @returns {Object}
 */
export default () => {
  const links = [
    {
      link: {
        name: 'subjects',
      },
      label: 'Предметы',
      labelPath: 'nav.subjects',
      class: 'm-subjects',
      notifications: 0,
    },
    {
      link: {
        name: 'lessons',
      },
      label: 'Уроки',
      labelPath: 'nav.lessons',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'online-lessons',
      },
      label: 'Online-уроки',
      labelPath: 'nav.online_lessons',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'student-statements',
      },
      label: 'Ведомость',
      labelPath: 'nav.statement',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'student-events',
      },
      label: 'События',
      labelPath: 'nav.events',
      class: 'm-events',
      notifications: 0,
    },
    {
      link: {
        name: 'student-showcase',
      },
      label: 'Витрина',
      // TODO: i18n
      labelPath: 'Витрина',
      class: 'm-showcase',
      notifications: 0,
      isExternal: true,
      externalUrl: `//${window.domains.showcase}/student/showcase`,
    },
    {
      link: {
        name: 'certification',
      },
      label: 'Аттестация',
      labelPath: 'nav.attestation',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'calendar',
      },
      label: 'Календарь',
      labelPath: 'nav.calendar',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'goals',
      },
      label: 'Цели',
      labelPath: 'nav.goals',
      class: 'm-goals',
      notifications: 0,
    },
    {
      link: {
        name: 'student-messages',
      },
      label: 'Сообщения',
      labelPath: 'nav.messages',
      class: 'm-messages',
      notifications: 0,
    },
    {
      link: {
        name: 'notifications',
      },
      label: 'Уведомления',
      labelPath: 'nav.notifications',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'student-news',
      },
      label: 'Новости',
      labelPath: 'nav.news',
      class: 'm-news',
      notifications: 0,
    },
    {
      link: {
        name: 'schedule-tests',
      },
      label: 'Контрольные',
      labelPath: 'nav.tests',
      class: 'm-schedule-tests',
      notifications: 0,
    },
    {
      link: {
        name: 'schedule-lessons',
      },
      label: 'Расписание',
      labelPath: 'nav.schedule',
      class: 'm-schedule-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'student-support',
      },
      label: 'Поддержка',
      labelPath: 'nav.support',
      class: 'm-help',
      notifications: 0,
    },
  ]

  return {
    currentTab: initialTab,
    links,
  }
}
