import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setJournal(state, payload) {
    state.journal = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setDatesRange(state, payload) {
    state.datesRange = payload
  },
}
