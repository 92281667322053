import ApiBase from 'ApiRest/ApiBase'

export const ApiContractDocument = new (class extends ApiBase {
  /**
   * Скачивание документов договора
   *
   * @param {number} contractId
   * @param {number} documentType
   * @returns {Promise}
   */
  get(contractId, documentType) {
    const url = `/account/client/contract-document/${contractId}?typeId=${documentType}`
    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
