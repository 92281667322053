import actions from '@frontend/store/showcase/common/invoices/actions'
import getters from '@frontend/store/showcase/common/invoices/getters'
import mutations from '@frontend/store/showcase/common/invoices/mutations'
import state from '@frontend/store/showcase/common/invoices/state'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
