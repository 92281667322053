import ApiBase from 'ApiRest/ApiBase'

export default new (class Lesson extends ApiBase {
  /**
   * //TODO: Не реализовано на бэке
   *
   * @param {number} studentId
   * @param {number} id
   * @returns {Promise}
   */
  // get(studentId, id) {
  //   return this._GET(`/account/client/students/${ studentId }/lessons/${ id }`)
  // }
})()
