import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiModulatorActiveModules = new (class extends ApiBase {
  /**
   * Доступные модули школы (или школ) для юзера и их настройки
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  get(parameters = {}) {
    const url = `/modulator/active-modules?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }
})()
