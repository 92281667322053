import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCertifications(state, payload) {
    state.certifications = payload
  },

  setTheCertification(state, payload) {
    const index = state.certifications.findIndex(
      (cert) => cert.id === payload.id,
    )

    Vue.set(state.certifications, index, payload)
  },
}
