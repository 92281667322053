import getErrorsList from '@frontend/helpers/getErrorsList'
import UserRouter from '@frontend/UserRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiSchool from 'ApiRest/Api/Auth/Options/School'
import ApiRegistration from 'ApiRest/Api/Auth/Registration'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import ApiConfirmEmail from 'ApiRest/Api/User/ConfirmEmail'
import { mainSchool } from 'Helpers/const/AppData'
import { isShowcaseDomain } from 'Helpers/domain'
import Vue from 'vue'

import { i18n } from '@/i18n/setup'
import { eventBus } from '@/pubsub/bus'

const globalMessageOnRegister = function () {
  eventBus.$emit('frontend.auth.clientCreated')
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async register({ state, commit }, recaptchaToken) {
    if (state.isLoading) {
      return false
    }

    commit('setIsLoading', true)

    const payload = {
      firstName: state.firstName,
      email: state.email,
      phone: state.phone,
      password: state.password,
      isAgreement: state.agreedWithPrivacyPolicy,
      isAgreedWithProcessingPD: state.agreedWithProcessingPD,
      isNotification: state.isNotification,
      recaptcha_token: recaptchaToken,
      registrationDomain: window.domains.main,
      fromShowcase: isShowcaseDomain,
    }

    try {
      const { status } = await ApiRegistration.post(payload)

      if (status === HttpStatusCodes.Created) {
        Vue.toasted.show(
          i18n.t(
            'store.auth.toasted_message.success.registration_was_successful',
          ),
          {
            type: 'success',
          },
        )

        commit('setInputValue', {
          name: 'isRegistrationSucceed',
          value: true,
        })

        globalMessageOnRegister()
      }
    } catch (error) {
      const message = error?.response?.data?.message ?? getErrorsList(error)

      Vue.toasted.show(message, {
        type: 'error',
      })
    }

    commit('setIsLoading', false)

    return true
  },

  async fetchSchoolPlatform({ state, commit }) {
    if (!state.schoolPlatform) {
      const domain = window.domains.main
      let { id } = mainSchool

      const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

      if (subdomainsList.length) {
        const school = subdomainsList.find((subdomain) => subdomain.self)

        if (school) {
          id = school.schoolId
        }
      }

      try {
        const { data } = await ApiSchool.get(id)

        commit('setInputValue', {
          name: 'schoolPlatform',
          value: data,
        })
      } catch (error) {
        commit('setInputValue', {
          name: 'schoolPlatform',
          value: {},
        })
      }
    }
  },

  async confirmEmail({ commit, state }) {
    commit('setIsLoading', true)

    const payload = {
      code: state.code,
    }

    // TODO: -> component
    try {
      await ApiConfirmEmail.patch(payload)

      Vue.toasted.show(
        i18n.t(
          'store.auth.toasted_message.success.mail_successfully_confirmed',
        ),
        {
          type: 'success',
        },
      )
    } catch (error) {
      const status = error?.response?.status

      commit('setInputValue', {
        name: 'isConfirmEmailFail',
        value: true,
      })

      let message = getErrorsList(error)

      if (status === HttpStatusCodes.NotFound) {
        message = i18n.t('store.auth.toasted_message.success.link_is_outdated')

        commit('setInputValue', {
          name: 'code',
        })

        UserRouter.router.push({
          name: 'login',
        })
      }

      Vue.toasted.show(message, {
        type: 'error',
      })
    }

    commit('setIsLoading', false)
  },
}
