// Сохранение настроек доменов в window
import 'Helpers/domains'
import '@/plugins/echarts'

import UserApp from '@frontend/App.vue'
import UserRouter from '@frontend/UserRouter'
import UserRouterShowcase from '@frontend/UserRouterShowcase'
import UserStore from '@frontend/UserStore'
import UserStoreShowcase from '@frontend/UserStoreShowcase'
import { getCookie } from 'Helpers/cookie'
import { isShowcaseDomain } from 'Helpers/domain'
import { LocaleControllerForShowcaseUser } from 'Helpers/models/LocaleControllerForShowcaseUser'
import SentryHelper from 'Helpers/SentryHelper'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueDeprecate from 'vue-deprecate'
import VueModal from 'vue-js-modal'
import { VueMasonryPlugin } from 'vue-masonry'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import VueSelect from 'vue-select'
import VueTippy from 'vue-tippy'
import VueToasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'

import { i18n } from '@/i18n/setup'
import { pubSubInit } from '@/pubsub/subscriber'

pubSubInit()
SentryHelper.init()

Vue.config.productionTip = false

if (process.iblsEnv.NODE_ENV === 'production') {
  Vue.config.silent = true
  Vue.config.debug = false
  Vue.config.devtools = false
}

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueTippy, {
  onShow(options) {
    return Boolean(options.props.content)
  },
})
Vue.use(VueMeta)
Vue.use(VCalendar)
Vue.use(VueDeprecate)
Vue.use(Vuelidate)
Vue.use(VueModal)
Vue.use(VueMasonryPlugin)
Vue.use(VueToasted, {
  position: 'bottom-center',
  duration: 2000,
})

Vue.component('VSelect', VueSelect)

const router = isShowcaseDomain ? UserRouterShowcase.init() : UserRouter.init()

const store = isShowcaseDomain ? UserStoreShowcase.init() : UserStore.init()

if (isShowcaseDomain) {
  const localeController = new LocaleControllerForShowcaseUser({
    lang: getCookie('lang') || 'ru',
  })

  localeController.changeLocale()
}

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(UserApp),
})

const preload = document.querySelector('#preload')

if (preload) {
  preload.remove()
}

app.$mount('#app')
