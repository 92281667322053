import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef {Object} EmailPayload
 * @property {number} chatId
 * @property {number} clientCode
 */

export default new (class extends ApiBase {
  /**
   * Подписаться на Push-уведомления.
   *
   * @returns {Promise}
   */
  post() {
    const url = `/notification/mobile_device`
    const data = {
      options: {},
    }

    return this._POST(url, data)
  }

  /**
   * Получить информацию о состоянии Push-уведомлений.
   *
   * @returns {Promise<NotificationChannelI<EmailPayload>>}
   */
  get() {
    const url = `/notification/mobile_device`

    return this._GET(url)
  }

  /**
   * Отписаться от Push-уведомлений.
   *
   * @returns {Promise}
   */
  delete() {
    const url = `/notification/mobile_device`

    return this._DELETE(url)
  }
})()
