export default {
  purchasesExtracurricular(state) {
    return state.purchases.filter(({ isExtracurricular }) => isExtracurricular)
  },

  purchasesCommon(state) {
    return state.purchases.filter(({ isExtracurricular }) => !isExtracurricular)
  },

  hasPurchases(state) {
    return state.purchases.length > 0
  },

  hasPurchasesExtracurricular(state, getters) {
    return getters.purchasesExtracurricular.length > 0
  },

  hasPurchasesCommon(state, getters) {
    return getters.purchasesCommon.length > 0
  },
}
