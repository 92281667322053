import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Список учебных годов у ученика очной формы обучения
   *
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/student/full-time-ctp-statement/academic-years`)
  }
})()
