import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTopic(state, payload) {
    state.topic = payload
  },

  updateContent(state, { contentId, isPassed }) {
    const content = state.topic.contents.find(({ id }) => id === contentId)

    if (content) {
      content.isPassed = isPassed
    }
  },
}
