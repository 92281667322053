<template>
  <footer class="s-footer">
    <ul v-if="isPreview" class="list-unstyled s-footer__nav">
      <li>
        <span class="link-dark">
          {{ t('school_website') }}
        </span>
      </li>

      <li>
        <span class="link-dark">
          {{ t('privacy_policy') }}
        </span>
      </li>
    </ul>

    <ul v-else class="list-unstyled s-footer__nav">
      <li v-if="schoolSite">
        <a
          class="link-dark"
          :href="schoolSite"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ t('school_website') }}
        </a>
      </li>

      <!--
        <li> <a class="link-dark" href="">Популярные вопросы</a></li>
        <li> <a class="link-dark" href="">Техническая поддержка</a></li>
      -->

      <li v-if="schoolPolicyFileUrl">
        <a
          class="link-dark"
          :href="schoolPolicyFileUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ t('privacy_policy') }}
        </a>
      </li>
    </ul>

    <div class="text-secondary">
      <div class="mb-2">© {{ t('intelligent_technologies') }}</div>

      <div>{{ t('id') }} {{ userId }}</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',

  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },

    school: {
      type: Object,
      default: null,
    },

    userId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    schoolSite() {
      return this.school?.site
    },

    schoolPolicyFileUrl() {
      return this.school?.policyFile?.fileUrl
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`layout.common.${name}`)
    },
  },
}
</script>
