import ApiBase from 'ApiRest/ApiBase'

export default new (class FeCertTest extends ApiBase {
  /**
   * Получить тест аттестации по ID
   *
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/student/test/fe-cert-tests/${id}`)
  }
})()
