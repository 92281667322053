export const guestShowcaseRoutes = [
  {
    path: 'showcase',
    component: () =>
      import(
        /* webpackChunkName: "showcase" */
        '@frontend/pages/common/showcase/Showcase.vue'
      ),

    children: [
      {
        path: '',
        name: 'guest-showcase',
        component: () =>
          import(
            /* webpackChunkName: "showcase-home" */
            '@frontend/pages/common/showcase/Home.vue'
          ),
      },

      {
        path: 'catalog',
        name: 'guest-showcase-catalog',
        component: () =>
          import(
            /* webpackChunkName: "showcase-catalog" */
            '@frontend/pages/common/showcase/Catalog.vue'
          ),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'guest-showcase-collection',
        component: () =>
          import(
            /* webpackChunkName: "showcase-collection" */
            '@frontend/pages/common/showcase/Collection.vue'
          ),
      },

      {
        path: 'products/:productId(\\d+)',
        name: 'guest-showcase-product',
        component: () =>
          import(
            /* webpackChunkName: "showcase-product" */
            '@frontend/pages/common/showcase/Product.vue'
          ),
      },

      {
        path: 'cart',
        name: 'guest-showcase-cart',
        component: () =>
          import(
            /* webpackChunkName: "showcase-cart" */
            '@frontend/pages/common/showcase/Cart.vue'
          ),
      },

      {
        path: '/cart/:offerId(\\d+)',
        name: 'guest-showcase-product-link',
        component: () =>
          import(
            /* webpackChunkName: "showcase-product-link" */
            '@frontend/pages/common/showcase/ProductLink.vue'
          ),
      },
    ],
  },
]
