import {
  add,
  addDays,
  format,
  isDate,
  isValid,
  isWithinInterval,
  parseISO,
  startOfWeek,
} from 'date-fns'
import { enUS, ru } from 'date-fns/locale'
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'
import dateFormat from 'Helpers/const/dateFormat'
import { getCookie } from 'Helpers/cookie'

const formatOptions = {
  locale: ru,
}
const { timeZone } = new window.Intl.DateTimeFormat().resolvedOptions()

if (getCookie('lang') === 'en') {
  formatOptions.locale = enUS
}

export const offset =
  getTimezoneOffset(timeZone) - getTimezoneOffset('Europe/Moscow')

/**
 * 0001-01-01 00:00:00 -> 0001-01-01T00:00:00
 *
 * @param {string} value
 * @returns {string|null}
 */
export const formatToIso = (value) => {
  if (!value) {
    return value
  }

  return value.split(' ').join('T')
}

/**
 * @param {Date|string} value
 * @param {string} mask
 * @returns {string|null}
 */
const formatDate = (value, mask) => {
  if (!value) {
    return null
  }

  if (isDate(value)) {
    return format(value, mask, formatOptions)
  }

  return format(parseISO(formatToIso(value)), mask, formatOptions)
}

export default {
  /**
   * Форматирует дату согласно маске
   *
   * @deprecated
   * @param {string} value
   * @param {string} formatValue
   * @returns {string|null}
   */
  getDate: (value, formatValue = dateFormat.DATE_FORMAT_FRONTEND) =>
    formatDate(value, formatValue),

  /**
   * Форматирует дату и время согласно маске
   *
   * @deprecated
   * @param {string} value
   * @param {string} formatValue
   * @returns {string|null}
   */
  getDateTime: (value, formatValue = dateFormat.DATE_TIME_FORMAT_FRONTEND) =>
    formatDate(value, formatValue),

  /**
   * 0001-01-01 00:00:00 -> 0001-01-01T00:00:00
   *
   * @deprecated
   * @param {string} value
   * @returns {string|null}
   */
  toIso(value) {
    if (!value) {
      return value
    }

    return value.split(' ').join('T')
  },
}

/**
 * Форматирует дату согласно маске
 *
 * @param {Date|string} value
 * @param {string} formatValue
 * @returns {string|null}
 */
export const getDate = (value, formatValue = dateFormat.DATE_FORMAT_FRONTEND) =>
  formatDate(value, formatValue)

/**
 * Форматирует дату и время согласно маске
 *
 * @param {string} value
 * @param {string} formatValue
 * @returns {string|null}
 */
export const getDateTime = (
  value,
  formatValue = dateFormat.DATE_TIME_FORMAT_FRONTEND,
) => formatDate(value, formatValue)

/**
 * Дата начала недели
 *
 * @param {Date} date
 * @returns {Date}
 */
export const getStartOfWeek = (date) =>
  startOfWeek(date, {
    weekStartsOn: 1,
  })

/**
 * Дата последнего дня недели
 *
 * @param {Date} date
 * @returns {Date}
 */
export const getEndOfWeek = (date) => addDays(getStartOfWeek(date), 6)

/**
 * Дата последнего дня рабочей недели (пятница)
 *
 * @param {Date} date
 * @returns {Date}
 */
export const getEndOfBusinessWeek = (date) => addDays(getStartOfWeek(date), 4)

/**
 * Сконвертировать Date в yyyy-MM-dd
 * (для текущей даты и периода fullcalendar)
 *
 * @param {Date} date
 * @returns {string}
 */
export const dateToDay = function (date = new Date()) {
  return format(date, dateFormat.DATE_FORMAT_BACKEND)
}

/**
 * Сконвертировать yyyy-MM-dd в yyyy для текущего года календаря
 *
 * @param {string} date
 * @returns {number}
 */
export const dayToYear = function (date = dateToDay()) {
  return Number(format(parseISO(date), 'yyyy'))
}

/**
 * Сконвертировать yyyy-MM в month yyyy
 *
 * @param {string} yearMonth
 * @returns {string}
 */
export const yearMonthToFullMonthName = function (yearMonth) {
  return getDate(`${yearMonth}-01`, 'LLLL yyyy')
}

/**
 * @param {string| Date} timeStart
 * @param {string| Date} timeEnd
 * @param {Object|null} timeStartOffset
 * @param {Object|null} timeEndOffset
 * @returns {boolean}
 */
export const isWithinIntervalTimeStartTimeEnd = ({
  timeStart,
  timeEnd,
  timeStartOffset = null,
  timeEndOffset = null,
}) => {
  const now = new Date().getTime()
  const date = new Date(now - offset)

  const tStart = isValid(timeStart) ? timeStart : parseISO(timeStart)
  const tEnd = isValid(timeEnd) ? timeEnd : parseISO(timeEnd)

  const start = timeStartOffset ? add(tStart, timeStartOffset) : tStart
  const end = timeEndOffset ? add(tEnd, timeEndOffset) : tEnd

  return isWithinInterval(date, {
    start,
    end,
  })
}

/**
 * @param {Date} date
 * @param {string} formatStr
 * @returns {number}
 * @todo Заменить возвращаемое значение на строку (не форматировать)
 */
export const getTimestamp = (date, formatStr = 'T') =>
  Number(formatInTimeZone(date, timeZone, formatStr))
