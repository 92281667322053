import { ApiShowcaseCategories } from 'ApiRest/Api/Showcase/Categories'
import { ApiShowcaseUpperCategories } from 'ApiRest/Api/Showcase/UpperCategories'

export default {
  async fetchCategories({ commit }) {
    const { data } = await ApiShowcaseCategories.get()

    commit('setCategories', data)
  },

  async fetchUpperCategories({ commit }) {
    const { data } = await ApiShowcaseUpperCategories.get()

    commit('setUpperCategories', data)
  },
}
