import actions from '@frontend/store/showcase/common/collection/actions'
import getters from '@frontend/store/showcase/common/collection/getters'
import mutations from '@frontend/store/showcase/common/collection/mutations'
import state from '@frontend/store/showcase/common/collection/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
