export default {
  hasContracts(state) {
    return Boolean(state.drafts.length) || Boolean(state.contracts.length)
  },

  contractsList(state) {
    return [...state.drafts, ...state.contracts]
  },

  contractById(state, getters) {
    return (id) => getters.contractsList.find((contract) => contract.id === id)
  },
}
