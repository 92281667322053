import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Расписание уроков
   *
   * @param studentId - ID студента
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(`/account/client/students/${studentId}/timetable`)
  }
})()
