export default () => ({
  agreedWithPrivacyPolicy: false,
  agreedWithProcessingPD: false,
  code: '',
  email: '',
  firstName: '',
  isConfirmEmailFail: false,
  isLoading: false,
  isNotification: false,
  isRegistrationSucceed: false,
  password: '',
  phone: '',
  schoolPlatform: null,
})
