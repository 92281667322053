import { LocaleController } from 'Helpers/models/LocaleController'

export class LocaleControllerForSchoolStudent extends LocaleController {
  constructor({ institutionId = null, lang }) {
    super({
      institutionType: 'school',
      institutionId,
      userType: 'student',
      lang,
    })
  }
}
