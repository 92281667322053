import { ApiEducontRegistration } from 'Api/rest/Api/Educont/Registration'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async register({ state }, smartcode) {
    const payload = {
      ...state.registerData,
      smartcode,
    }

    const response = await ApiEducontRegistration.post(payload)

    return response
  },
}
