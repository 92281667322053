const authDomain = process.iblsEnv.IBLS_AUTH_DOMAIN
const { protocol } = window.location

const getMainDomain = () => {
  switch (window.location.host) {
    case window.domains.mobile:
      return `${protocol}//${window.domains.mobile}`

    case window.domains.showcase:
      return `${protocol}//${window.domains.showcase}`

    default:
      return `${protocol}//${window.domains.main}`
  }
}

const mainDomain = getMainDomain()

export const authUrl = {
  login: authDomain ? `${authDomain}/login` : `${mainDomain}/login`,
  logout: authDomain ? `${authDomain}/logout` : `${mainDomain}/logout`,
  recover: authDomain ? `${authDomain}/recover` : `${mainDomain}/recover`,
  authAny: authDomain
    ? `${authDomain}/auth/any/7538`
    : `${mainDomain}/auth/any/7538`,
}
