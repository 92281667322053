import ApiFamilyCertifications from 'ApiRest/Api/Account/Student/Test/FamilyCertification/List'
import ApiFeCertComplete from 'ApiRest/Api/Account/Student/Test/FeCertComplete'
import Vue from 'vue'

import { i18n } from '@/i18n/setup'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async fetchCertifications({ commit }) {
    const { data } = await ApiFamilyCertifications.get()

    commit('setCertifications', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async completeCertification({ commit }) {
    try {
      const { data } = await ApiFeCertComplete.put()

      commit('setTheCertification', data)

      // TODO: -> component
      Vue.toasted.show(
        i18n.t('store.student.toasted_message.certification_completed'),
        {
          type: 'success',
          duration: 5000,
        },
      )
    } catch (error) {
      // TODO: -> component
      const message =
        error?.response?.data?.message ||
        i18n.t('store.student.toasted_message.error_has_occurred')

      Vue.toasted.show(message, {
        type: 'error',
        duration: 5000,
      })

      throw error
    }
  },
}
