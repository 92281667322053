import { getCookie } from 'Helpers/cookie'
import { LocaleController } from 'Helpers/models/LocaleController'

export default {
  async changeLocal() {
    await new LocaleController({
      institutionType: 'school',
      userType: 'guest',
      lang: getCookie('lang') || 'ru',
    }).changeLocale()
  },
}
