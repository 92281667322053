import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setQuestions(state, payload) {
    state.questions = payload
  },

  setTheQuestion(state, payload) {
    state.theQuestion = payload
  },

  setTheQuestionValue(state, { path, value }) {
    set(state.theQuestion, path, value)
  },

  setNewQuestionValue(state, { path, value }) {
    set(state.newQuestion, path, value)
  },

  resetNewQuestion(state) {
    state.newQuestion.text = ''
    state.newQuestion.files = []
  },

  setNewCommentValue(state, { path, value }) {
    set(state.newComment, path, value)
  },

  resetNewComment(state) {
    state.newComment.text = ''
    state.newComment.files = []
  },

  setIsLoading(state, { key, value }) {
    state.isLoading[key] = value
  },

  setQuestionsInWorkCount(state, payload) {
    state.questionsInWorkCount = payload
  },
}
