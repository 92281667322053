import main from '@frontend/store/showcase/client/main/store'
import profile from '@frontend/store/showcase/client/profile/store'

export default {
  namespaced: true,
  modules: {
    main,
    profile,
  },
}
