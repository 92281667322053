/**
 * @constant
 * @type {number[] | null}
 */
const chatSchoolList = process.iblsEnv.IBLS_CHAT_SCHOOL_LIST
  ? JSON.parse(process.iblsEnv.IBLS_CHAT_SCHOOL_LIST)
  : null

/**
 * @param {'client' | 'student'} user
 * @returns {any}
 */
export default (user) => ({
  isEnabled(state, getters, rootState) {
    const isEnabledGlobally = process.iblsEnv.IBLS_CHAT_ENABLED === '1'
    const isEnabledForEachSchool = chatSchoolList === null

    if (!isEnabledGlobally) {
      return false
    }

    if (isEnabledForEachSchool) {
      return true
    }

    let schoolId

    switch (user) {
      case 'client': {
        schoolId = rootState.client.students?.school?.id
        break
      }
      case 'student': {
        schoolId = rootState.student.profile?.profile?.school?.id
        break
      }

      default: {
        //
      }
    }

    if (!schoolId) {
      return false
    }

    if (!chatSchoolList.includes(schoolId)) {
      return false
    }

    return true
  },
})
