import AxiosHelper from 'Helpers/AxiosHelper'

const BASE_URL = `${window.domains.apiUrl}`

export default new (class Timestamp extends AxiosHelper {
  /**
   * Серверное время. Timestamp в сек
   *
   * @returns {Promise}
   */
  get() {
    return this.GET(`${BASE_URL}/system/get-timestamp`)
  }
})()
