import { WebAnalyticsHelper } from 'Helpers/WebAnalyticsHelper'

export const promocode = {
  /**
   * Клиент использовал промо-код на услугу
   */
  applied() {
    WebAnalyticsHelper.goalPromocode()
  },
}
