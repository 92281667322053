import { isBefore, parseISO } from 'date-fns'
import dateFormatHelper from 'Helpers/dateFormatHelper'
import { cloneDeep } from 'lodash'

export default {
  getAllNotificationsSortedByDate(state) {
    return cloneDeep(state.notifications).sort((a, b) => {
      if (
        isBefore(
          parseISO(dateFormatHelper.toIso(a.date)),
          parseISO(dateFormatHelper.toIso(b.date)),
        )
      ) {
        return 1
      }

      return -1
    })
  },
}
