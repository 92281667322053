import 'formdata-polyfill'

import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import UploadException from 'Api/exceptions/Upload'
import AxiosHelper from 'Helpers/AxiosHelper'

const REST_URL = `${window.domains.apiUrl}/rest`
const BASE_UPLOAD_URL = `${window.domains.apiUrl}/upload`

export default new (class Upload extends AxiosHelper {
  constructor() {
    super()
    this.imageUrl = `${BASE_UPLOAD_URL}/image`
    this.fileUrl = `${BASE_UPLOAD_URL}/file`
    this.privateFileUrl = `${BASE_UPLOAD_URL}/private-file`
  }

  /**
   * @param {Object} error
   * @throws {UploadException}
   * @private
   */
  static _error(error) {
    if (!error.response) {
      throw new UploadException('Ошибка отправки файла')
    }

    const { data, status } = error.response

    if (data[0] && data[0].message) {
      throw new UploadException(data[0].message)
    }

    if (status === HttpStatusCodes.UnprocessableEntity) {
      throw new UploadException('Тип файла запрещен для загрузки')
    }

    if (status === HttpStatusCodes.PayloadTooLarge) {
      throw new UploadException('Размер файла слишком большой')
    }
  }

  /**
   * @param {string} url
   * @param {Blob} file
   * @param {Function} onUploadProgress
   * @returns {Promise}
   * @private
   */
  _send(url, file, onUploadProgress = function () {}) {
    const data = new FormData()

    data.set('file', file)

    return this.POST(url, data, onUploadProgress).catch((error) =>
      Upload._error(error),
    )
  }

  /**
   * @param {Blob} image
   * @param {Function} onUploadProgress
   * @returns {Promise}
   */
  sendImage(image, onUploadProgress = function () {}) {
    return this._send(this.imageUrl, image, onUploadProgress)
  }

  /**
   * Загрузка аватарки
   *
   * @param {string} url
   * @param {Blob} file
   * @returns {Promise}
   */
  sendPhoto(url, file) {
    const resultUrl = `${REST_URL}${url}`

    return this._send(resultUrl, file)
  }

  /**
   * @param {Blob} file
   * @param {Function} onUploadProgress
   * @param {boolean} isPrivate
   * @returns {Promise}
   */
  sendFile(file, onUploadProgress = function () {}, isPrivate = false) {
    const url = isPrivate === true ? this.privateFileUrl : this.fileUrl

    return this._send(url, file, onUploadProgress)
  }
})()
