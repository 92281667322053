import ApiBase from 'ApiRest/ApiBase'

export default new (class LessonList extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/student/lessons')
  }
})()
