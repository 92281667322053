var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm._t("header"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "s-page", class: { noticed: _vm.noticed } },
        [
          _vm._t("aside"),
          _vm._v(" "),
          _vm.main && !_vm.isClient
            ? _c(
                "div",
                { staticClass: "s-content" },
                [
                  _c(
                    "ContentWithPreloader",
                    { attrs: { "is-loading": _vm.isLoadingSwitchStudent } },
                    [
                      _c(
                        "main",
                        { staticClass: "h-main" },
                        [
                          _vm._t("tabs-nav"),
                          _vm._v(" "),
                          _vm._t("subjects"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "home__col" },
                            [
                              _c(
                                "div",
                                { staticClass: "inner" },
                                [_vm._t("default")],
                                2
                              ),
                              _vm._v(" "),
                              _vm._t("footer"),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.main && _vm.isClient
            ? _c(
                "div",
                { staticClass: "s-content" },
                [
                  _c(
                    "ContentWithPreloader",
                    { attrs: { "is-loading": _vm.isLoadingSwitchStudent } },
                    [
                      _c(
                        "main",
                        { staticClass: "s-main" },
                        [_vm._t("tabs-nav"), _vm._v(" "), _vm._t("default")],
                        2
                      ),
                      _vm._v(" "),
                      _vm._t("footer"),
                    ],
                    2
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "s-content" },
                [
                  _c("main", { staticClass: "s-main" }, [_vm._t("default")], 2),
                  _vm._v(" "),
                  _vm._t("footer"),
                ],
                2
              ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }