import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseCart = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {number} payload.id
   * @param {Object} payload.consumer
   * @param {number} payload.consumer.id
   * @returns {Promise}
   */
  post(payload) {
    const url = '/cart'

    return this._POST(url, payload)
  }

  /**
   * @param {Object} payload
   * @param {number} payload.id
   * @param {Object} payload.consumer
   * @param {number} payload.consumer.id
   * @returns {Promise}
   */
  delete(payload) {
    const url = `/cart`

    return this._DELETE(url, {
      data: payload,
    })
  }
})()
