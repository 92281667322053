import { ApiShowcaseSupportQuestionsComment } from 'ApiRest/Api/Showcase/Support/Comment'
import { ApiShowcaseSupportInWorkCount } from 'ApiRest/Api/Showcase/Support/InWorkCount'
import { ApiShowcaseSupportQuestion } from 'ApiRest/Api/Showcase/Support/Question'
import { ApiShowcaseSupportQuestions } from 'ApiRest/Api/Showcase/Support/Questions'

export default {
  async fetchQuestions({ commit }) {
    const { data } = await ApiShowcaseSupportQuestions.get()

    commit('setQuestions', data)
  },

  async fetchTheQuestion({ commit, getters }) {
    const { data } = await ApiShowcaseSupportQuestion.get(getters.questionId)

    commit('setTheQuestion', data)
  },

  async updateTheQuestion({ state, getters }) {
    await ApiShowcaseSupportQuestion.patch(
      getters.questionId,
      state.theQuestion,
    )
  },

  async addNewComment({ state, getters }) {
    await ApiShowcaseSupportQuestionsComment.post(
      getters.questionId,
      state.newComment,
    )
  },

  async createQuestion({ state }) {
    await ApiShowcaseSupportQuestion.post(state.newQuestion)
  },

  async fetchQuestionsInWorkCount({ commit }) {
    const { data } = await ApiShowcaseSupportInWorkCount.get()

    commit('setQuestionsInWorkCount', data)
  },
}
