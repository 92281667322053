import { ApiSupportQuestion } from 'ApiRest/Api/Support/Question'
import { ApiSupportQuestionComment } from 'ApiRest/Api/Support/Question/Comment'
import { ApiSupportQuestionInWorkCount } from 'ApiRest/Api/Support/Question/InWorkCount'
import { ApiSupportQuestionList } from 'ApiRest/Api/Support/Question/List'
import { cloneDeep } from 'lodash'

export default {
  async fetchQuestions({ commit, rootGetters }) {
    const { data } = await ApiSupportQuestionList.get(
      rootGetters['common/support/common/defaultParamForClient'],
    )

    commit('setQuestions', data)
  },

  async fetchTheQuestion({ commit, getters, rootGetters }) {
    const { data } = await ApiSupportQuestion.get(
      getters.questionId,
      rootGetters['common/support/common/defaultParamForClient'],
    )

    commit('setTheQuestion', data)
  },

  async updateTheQuestion({ state, getters, rootGetters }) {
    await ApiSupportQuestion.patch(
      getters.questionId,
      state.theQuestion,
      rootGetters['common/support/common/defaultParamForClient'],
    )
  },

  async addNewComment({ state, getters, rootGetters }) {
    await ApiSupportQuestionComment.post(
      getters.questionId,
      state.newComment,
      rootGetters['common/support/common/defaultParamForClient'],
    )
  },

  async createQuestion({ state, rootGetters }) {
    const isClient = rootGetters['common/user/isClient']
    const currentStudentId = rootGetters['client/students/currentStudentId']

    const payload = cloneDeep(state.newQuestion)

    if (isClient && currentStudentId) {
      payload.student = {
        id: currentStudentId,
      }
    }

    await ApiSupportQuestion.post(
      payload,
      rootGetters['common/support/common/defaultParamForClient'],
    )
  },

  async fetchQuestionsInWorkCount({ commit, rootGetters }) {
    const { data } = await ApiSupportQuestionInWorkCount.get(
      rootGetters['common/support/common/defaultParamForClient'],
    )

    commit('setQuestionsInWorkCount', data)
  },
}
