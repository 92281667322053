var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    _vm._g({ staticClass: "btn", attrs: { type: "button" } }, _vm.$listeners),
    [
      _vm.isLoading
        ? _c("span", {
            staticClass: "spinner-border spinner-border-sm mr-2",
            attrs: { role: "status", "aria-hidden": "true" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }