import calendar from '@frontend/store/student/calendar/store'
import events from '@frontend/store/student/events/store'
import extramuralCertification from '@frontend/store/student/extramuralCertification/store'
import familyCertification from '@frontend/store/student/familyCertification/store'
import fullTimeCertification from '@frontend/store/student/fullTimeCertification/store'
import fullTimeStatement from '@frontend/store/student/fullTimeStatement/store'
import goals from '@frontend/store/student/goals/store'
import homework from '@frontend/store/student/homework/store'
import journal from '@frontend/store/student/journal/store'
import lessonPreview from '@frontend/store/student/lessonPreview/store'
import lessons from '@frontend/store/student/lessons/store'
import lessonsOnline from '@frontend/store/student/lessonsOnline/store'
import main from '@frontend/store/student/main/store'
import messages from '@frontend/store/student/messages/store'
import news from '@frontend/store/student/news/store'
import profile from '@frontend/store/student/profile/store'
import scheduleLessons from '@frontend/store/student/scheduleLessons/store'
import scheduleTests from '@frontend/store/student/scheduleTests/store'
import statements from '@frontend/store/student/statements/store'
import subjects from '@frontend/store/student/subjects/store'
import test from '@frontend/store/student/test/store'

export default {
  namespaced: true,

  modules: {
    calendar,
    events,
    extramuralCertification,
    familyCertification,
    fullTimeCertification,
    goals,
    lessonPreview,
    lessons,
    lessonsOnline,
    main,
    news,
    profile,
    statements,
    subjects,
    test,
    homework,
    scheduleTests,
    journal,
    fullTimeStatement,
    scheduleLessons,
    messages,
  },
}
