import HttpHeaders from 'Api/const/HttpHeaders'
import ApiAccountStudentNews from 'ApiRest/Api/Account/Student/News'
import ApiAccountStudentNewsList from 'ApiRest/Api/Account/Student/News/List'
import ApiAccountStudentNewsTags from 'ApiRest/Api/Account/Student/News/Tags'

export default {
  /**
   * Список новостей
   *
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {number} [payload.page] - номер текущей страницы новостей
   * @returns {Promise<void>}
   */
  async fetchNews({ commit }, payload) {
    const { data, headers } = await ApiAccountStudentNewsList.get(payload)
    const { PAGINATION_PAGES_TOTAL, PAGINATION_PAGE } = HttpHeaders

    if (payload?.page === 1) {
      commit('setNews', data)
    } else {
      commit('pushNews', data)
    }

    commit('setNewsPaginationPageCount', headers[PAGINATION_PAGES_TOTAL])
    commit('setNewsPaginationCurrentPage', headers[PAGINATION_PAGE])
  },

  /**
   * Список тегов
   *
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  async fetchTags({ commit }) {
    const { data } = await ApiAccountStudentNewsTags.get()

    commit('setTags', data)
  },

  /**
   * Отдельная новость
   *
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} id - id новости
   * @returns {Promise<void>}
   */
  async fetchNewsArticle({ commit }, id) {
    const { data } = await ApiAccountStudentNews.get(id)

    commit('setNewsArticle', data)
  },

  resetState({ commit }) {
    commit('resetState')
  },
}
