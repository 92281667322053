/**
 * Редирект на страницу микросервиса авторизации
 *
 * @param {string} url
 * @param {string|null} [target]
 */
export const redirectToAuth = (url, target = null) => {
  window.location.href = target ? `${url}?targetUrl=${target}` : url
}

/**
 * Редирект на админку
 */
export const redirectToAdminArea = () => {
  window.location.href = `//${window.domains.admin}`
}

/**
 * Редирект на ЛК
 */
export const redirectToUserArea = () => {
  window.location.href = `//${window.domains.main}`
}

/**
 * Редирект на origin
 */
export const redirectToOrigin = () => {
  window.location.href = window.location.origin
}

// TODO: Удалить после перехода на МСА
export const redirectAfterLogin = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const targetUrl = searchParams.get('targetUrl')

  if (targetUrl) {
    window.location.href = targetUrl
  } else {
    redirectToOrigin()
  }
}
