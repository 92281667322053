import actions from '@frontend/store/showcase/common/cart/actions'
import getters from '@frontend/store/showcase/common/cart/getters'
import mutations from '@frontend/store/showcase/common/cart/mutations'
import state from '@frontend/store/showcase/common/cart/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
