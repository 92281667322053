import ApiBase from 'ApiRest/ApiBase'

export default new (class Goal extends ApiBase {
  /**
   * @param {Object} data
   * @property {string} reachDate
   * @property {string} text
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/account/student/goals', data)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/account/student/goals/${id}`)
  }
})()
