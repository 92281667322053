import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportIdeaLike = new (class extends ApiBase {
  /**
   * @param {number} ideaId
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(ideaId, parameters) {
    const url = `/support/ideas/${ideaId}/like?${UrlHelper.param(parameters)}`

    return this._POST(url)
  }
})()
