import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class extends ApiBase {
  /**
   * Список общих средних оценок по всем предметам за неделю
   *
   * @param {number} studentId - ID студента
   * @param {Object} parameters
   * @returns {Promise<?>}
   */
  get(studentId, parameters = {}) {
    const urlParams = UrlHelper.param(parameters)
    const url =
      `/account/client/students/${studentId}` +
      `/full-time-ctp-statement/average-mark` +
      `/total/weekly?${urlParams}`

    return this._GET(url)
  }
})()
