var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "subject__progress",
      style: { backgroundColor: "#" + _vm.color + _vm.opacity },
    },
    [
      _c("div", {
        staticClass: "progress-bar",
        style: {
          backgroundColor: "#" + _vm.color,
          width: _vm.progress + "%",
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "checkpoint",
        style: { width: _vm.checkpoint + "%" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }