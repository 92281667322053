/**
 * @typedef {Object} SupportIdeaPreview
 * @property {number} id - Ид вопроса
 * @property {string} title - Заголовок идеи
 * @property {SupportIdeaAuthor} author - Автор идеи
 * @property {number} commentsCount - Кол-во комментариев
 * @property {number} voteCount - Кол-во голосов
 * @property {boolean} isVoted - Проголосовал ли текущий пользователь
 * @property {SupportIdeaStatus} status - Статус идеи
 */

/**
 * @typedef {Object} SupportIdeaAuthor
 * @property {number} id - Ид вопроса
 * @property {string} firstName - Имя
 * @property {string} lastName - Фамилия
 * @property {string} middleName - Отчество
 * @property {Object|null} photo - Фото профиля
 * @property {('Клиент'|'Ученик')} role - Роль
 */

/**
 * @typedef {Object} SupportIdeaComment
 * @property {number} id - Ид комментария
 * @property {SupportIdeaAuthor} author - Автор вопроса
 * @property {Array} title - Заголовок идеи
 * @property {string} text - Текст идеи
 */

/**
 * @typedef {Object} SupportIdea
 * @property {number} id - Ид обращения
 * @property {string} title - Заголовок идеи
 * @property {string} text - Текст идеи
 * @property {boolean} isVoted - Проголосовал ли текущий пользователь
 * @property {SupportIdeaStatus} status - Статус идеи
 * @property {SupportIdeaAuthor} author - Автор идеи
 * @property {SupportIdeaComment[]} comments - Комментарии
 * @property {number} voteCount - Кол-во голосов
 * @property {number} commentsCount - Кол-во комментариев
 * @property {string} createdAt - Дата создания время в формате ISO8601
 */

/**
 * @typedef {Object} SupportNewIdea
 * @property {string} title - Заголовок идеи
 * @property {string} text - Текст идеи
 */

/**
 * @typedef {Object} SupportIdeaStatus
 * @property {number} id - Ид статуса
 * @property {('На модерации'|'Популярные'|'Реализовано'|'Отклонено'|'Удалено')} name - Название
 *   статуса
 */

export default () => ({
  /**
   * @type {SupportIdeaPreview[]}
   */
  ideas: [],

  /**
   * @type {SupportIdea}
   */
  theIdea: {
    id: null,
    title: '',
    text: '',
    isVoted: false,
    status: {
      id: null,
      name: '',
    },
    author: {
      id: null,
      firstName: '',
      lastName: '',
      middleName: '',
      role: '',
      photo: null,
    },
    comments: [],
    voteCount: null,
    commentsCount: null,
    createdAt: '',
  },

  /**
   * @type {SupportNewIdea} - Новая идея
   */
  newIdea: {
    title: '',
    text: '',
  },

  newComment: {
    text: '',
  },

  options: {
    /**
     * @type {SupportIdeaStatus[]} - Статусы идеи
     */
    statuses: [],
  },

  optionsSelected: {
    /**
     * @type {SupportIdeaStatus} - Статус идеи
     */
    status: null,
  },

  isLoading: {
    content: false,
    ideas: false,
    newIdea: false,
  },
})
