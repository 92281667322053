import { Pagination } from '@admin/helpers/admin/CompositePagination'
import { sortOptions } from '@frontend/store/showcase/common/catalog/_data/options'

export default () => ({
  /** @type {ShowcaseProduct[]} */
  products: [],

  /** @type {ShowcaseProduct[]} */
  productsPopular: [],

  /** @type {string} */
  search: '',

  /** @type {string} */
  tag: '',

  /** @type {null|number} */
  categoryId: null,

  sort: {
    value: sortOptions[0],
    options: sortOptions,
  },

  pagination: new Pagination({
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
  }),

  /** @type {ShowcaseFilter[]} */
  filters: [],

  /** @type {Object} */
  filtersOptionsFullyDisplayedByCode: {},
})
