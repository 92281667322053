<template>
  <LayoutBase>
    <template #header>
      <TheHeaderGuest :school-name="schoolName" />
    </template>

    <router-view />

    <template #footer>
      <TheFooter />
    </template>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@frontend/layouts/common/main/LayoutBase.vue'
import TheHeaderGuest from '@frontend/layouts/guest/TheHeaderGuest.vue'
import TheFooter from '@frontend/layouts/showcase/TheFooter.vue'

export default {
  name: 'GuestMain',

  components: {
    TheFooter,
    TheHeaderGuest,
    LayoutBase,
  },

  props: {
    schoolName: {
      type: String,
      required: true,
    },
  },
}
</script>
