import ApiBase from 'ApiRest/ApiBase'

export default new (class TheInvoicePaymentUrl extends ApiBase {
  /**
   * @param {number} invoiceId
   * @returns {Promise}
   */
  get(invoiceId) {
    return this._GET(`/account/invoices/${invoiceId}/get-url`)
  }
})()
