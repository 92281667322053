import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportQuestion = new (class extends ApiBase {
  /**
   * @param {number} questionId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(questionId, parameters = {}) {
    const url = `/support/questions/${questionId}?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }

  /**
   * @param {number} questionId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  patch(questionId, payload, parameters = {}) {
    const url = `/support/questions/${questionId}?${UrlHelper.param(
      parameters,
    )}`

    return this._PATCH(url, payload)
  }

  /**
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(payload, parameters = {}) {
    const url = `/support/question?${UrlHelper.param(parameters)}`

    return this._POST(url, payload)
  }
})()
