import { get } from 'lodash'

import { eventBus } from './bus'
import { eventHandlers } from './handlers'

export const pubSubInit = function () {
  eventBus.$on('*', (type, e) => {
    const handler = get(eventHandlers, type)

    if (handler && typeof handler === 'function') {
      handler(e)
    }
  })
}
