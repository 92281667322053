import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setIdeas(state, payload) {
    state.ideas = payload
  },

  setTheIdea(state, payload) {
    state.theIdea = payload
  },

  setTheIdeaValue(state, { path, value }) {
    set(state.theIdea, path, value)
  },

  setNewIdeaValue(state, { path, value }) {
    set(state.newIdea, path, value)
  },

  resetNewIdea(state) {
    state.newIdea.title = ''
    state.newIdea.text = ''
  },

  setNewCommentValue(state, { path, value }) {
    set(state.newComment, path, value)
  },

  resetNewComment(state) {
    state.newComment.text = ''
  },

  setOption(state, { path, value }) {
    set(state.options, path, value)
  },

  setOptionSelected(state, { path, value }) {
    set(state.optionsSelected, path, value)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.path
   * @param {boolean} payload.value
   */
  setIsLoading(state, { path, value }) {
    state.isLoading[path] = value
  },
}
