/**
 * Есть ли перечисленные модули в массиве доступных для юзера модулей
 *
 * @param {Object[]} activeModules
 * @param {string[]} moduleIdsSearched
 * @returns {boolean}
 */
export const hasModules = function (activeModules, moduleIdsSearched) {
  return activeModules.some((module) => moduleIdsSearched.includes(module.id))
}

/**
 * Получить модуль и его настройки из массива доступных для юзера модулей
 *
 * @param {Object[]} activeModules
 * @param {string} moduleId
 * @returns {Object|undefined}
 */
export const getActiveModule = function (activeModules, moduleId) {
  return activeModules.find((module) => module.id === moduleId)
}

/**
 * Получить настройки из массива доступных для юзера модулей
 *
 * @param {Object} obj
 * @param {Object[]} obj.activeModules
 * @param {string} obj.moduleId
 * @param {string} obj.settingId
 * @returns {*}
 */
export const getModuleSettingValue = function ({
  activeModules,
  moduleId,
  settingId,
}) {
  const moduleAvailable = activeModules.find((module) => module.id === moduleId)

  if (!moduleAvailable) {
    return moduleAvailable
  }

  return moduleAvailable.settings[settingId]
}
