import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonIsRunning = new (class extends ApiBase {
  /**
   * Онлайн-урок Big Blue Button запущен или нет
   *
   * @param {number} onlineLessonId
   * @returns {Promise<boolean>}
   */
  get(onlineLessonId) {
    return this._GET(
      `/account/student/online-lessons/${onlineLessonId}/is-running`,
    )
  }
})()
