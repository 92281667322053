<template>
  <div class="loader-full-screen">
    <div class="loader-full-screen__inner">
      <div class="loader-full-screen__dot"></div>
      <div class="loader-full-screen__dot"></div>
      <div class="loader-full-screen__dot"></div>
      <div class="loader-full-screen__dot"></div>
      <div class="loader-full-screen__dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderFullScreen',
}
</script>

<style lang="scss">
@import '~@frontend/scss/variables';

.loader-full-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: $blue;
  align-items: center;
  justify-content: center;

  &__inner {
    width: calc(100% - 30px);
    padding: 0 15px;
    text-align: center;
    white-space: nowrap;
  }

  &__dot {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      content: '';
      background: #fff;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      transform-origin: center;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

$key-frames-ms: 600ms, 500ms, 400ms, 300ms, 200ms, 100ms;

@each $current-ms in $key-frames-ms {
  $i: index($key-frames-ms, $current-ms);
  .loader-full-screen__inner .loader-full-screen__dot:nth-child(#{$i}):before {
    animation: loader 2s $current-ms infinite ease-in-out;
  }
}

@keyframes loader {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
  }

  50% {
    transform: translate(-50%, -50%) scale(2);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.5);
  }
}
</style>
