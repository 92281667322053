<template>
  <div ref="nav" :class="$style.tabs">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      :ref="tab.id"
      :class="[
        $style.tabItem,
        { [$style.tabItemActive]: currentTab === tab.id },
      ]"
      class="cursor-pointer link-blue"
      @click="onClickTabNav(tab.id)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsNav',

  props: {
    tabs: {
      type: Object,
      default: null,
    },

    currentTab: {
      type: String,
      default: '',
    },
  },

  watch: {
    /**
     * @param {string} value
     * @description для мобильной версии: скроллит навигацию к активному внутреннему итему
     */
    currentTab(value) {
      this.$refs.nav.scrollLeft = this.$refs[value][0].offsetLeft
    },
  },

  methods: {
    onClickTabNav(tabId) {
      this.$emit('change', tabId)
    },
  },
}
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.tabs {
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;

  @media (min-width: $lg) {
    display: none;
  }
}

.tabItem {
  display: inline-block;
  padding: 16px;
  font-family: $montserrat;
  font-size: 20px;
  font-weight: bold !important;
  line-height: 26px;
}

.tabItemActive {
  color: $black !important;
}
</style>
