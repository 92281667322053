import averageMark from '@frontend/store/client/averageMark/store'
import calendar from '@frontend/store/client/calendar/store'
import contracts from '@frontend/store/client/contracts/store'
import demoAccess from '@frontend/store/client/demoAccess/store'
import events from '@frontend/store/client/events/store'
import extramuralCertification from '@frontend/store/client/extramuralCertification/store'
import familyCertification from '@frontend/store/client/familyCertification/store'
import familyCertificationRequest from '@frontend/store/client/familyCertificationRequest/store'
import fullTimeCertification from '@frontend/store/client/fullTimeCertification/store'
import fullTimeStatement from '@frontend/store/client/fullTimeStatement/store'
import goals from '@frontend/store/client/goals/store'
import homework from '@frontend/store/client/homework/store'
import invoices from '@frontend/store/client/invoices/store'
import journal from '@frontend/store/client/journal/store'
import lessons from '@frontend/store/client/lessons/store'
import main from '@frontend/store/client/main/store'
import messages from '@frontend/store/client/messages/store'
import news from '@frontend/store/client/news/store'
import notifications from '@frontend/store/client/notifications/store'
import profile from '@frontend/store/client/profile/store'
import scheduleLessons from '@frontend/store/client/scheduleLessons/store'
import statements from '@frontend/store/client/statements/store'
import students from '@frontend/store/client/students/store'
import theContract from '@frontend/store/client/theContract/store'
import theContractSimplified from '@frontend/store/client/theContractSimplified/store'

export default {
  namespaced: true,
  modules: {
    journal,
    calendar,
    contracts,
    demoAccess,
    extramuralCertification,
    familyCertification,
    fullTimeCertification,
    familyCertificationRequest,
    goals,
    invoices,
    lessons,
    main,
    news,
    notifications,
    profile,
    statements,
    students,
    theContract,
    homework,
    fullTimeStatement,
    averageMark,
    scheduleLessons,
    events,
    theContractSimplified,
    messages,
  },
}
