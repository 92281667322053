import { LocaleController } from 'Helpers/models/LocaleController'

export class LocaleControllerForSchoolClient extends LocaleController {
  constructor({ lang }) {
    super({
      institutionType: 'school',
      userType: 'client',
      lang,
    })
  }
}
