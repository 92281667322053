import ApiBase from 'ApiRest/ApiBase'

export default new (class List extends ApiBase {
  /**
   * Получить возвращенные Ответы у Попытки
   *
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/student/test/attempts/${id}/answers-active`)
  }
})()
