import { ApiUserAction } from 'ApiRest/Api/UserAction'
import { trackActionTypes } from 'Helpers/const/trackActionTypes'

/**
 * Отслеживание посещений страниц
 *
 * @param {{name: string, params: Object}} to
 * @param {{params: Object}} from
 * @returns {Promise}
 */
export const trackPage = async (to, from) => {
  // Трекинг всех страниц студента
  await ApiUserAction.post({
    actionTypeId: trackActionTypes.VISIT,
    entityId: null,
    url: window.location.href,
  })

  const lessonRoutes = ['lesson', 'subject-lesson']

  let payload = null

  // Доп. трекинг определенных страниц
  if (
    lessonRoutes.includes(to.name) &&
    from.params.lessonId !== to.params.lessonId
  ) {
    payload = {
      actionTypeId: trackActionTypes.VISIT_LESSON_PAGE,
      entityId: Number(to.params.lessonId),
    }
  }

  if (to.name === 'test-hello-ctp') {
    payload = {
      actionTypeId: trackActionTypes.VISIT_TEST_PAGE,
      entityId: Number(to.params.ctpTestId),
    }
  }

  if (to.name === 'test-hello-fte-ctp') {
    payload = {
      actionTypeId: trackActionTypes.VISIT_TEST_PAGE,
      entityId: Number(to.params.fteCtpTestId),
    }
  }

  if (to.name === 'test-hello-fe-cert') {
    payload = {
      actionTypeId: trackActionTypes.VISIT_TEST_ATTESTATION_PAGE,
      entityId: Number(to.params.feCertTestId),
    }
  }

  if (payload !== null) {
    await ApiUserAction.post(payload)
  }
}
