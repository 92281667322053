import * as Sentry from '@sentry/vue'
import Vue from 'vue'

/**
 * @param {string} url
 * @param {number | null} code
 * @returns {boolean}
 */
function isDenied(url, code = null) {
  const denyUrls = ['yandex', 'google']
  const denyCodes = [403]

  let denied = false

  denyUrls.forEach((denyUrl) => {
    if (url.includes(denyUrl)) {
      denied = true
    }
  })

  if (code && denied === false) {
    denyCodes.forEach((denyCode) => {
      if (code === denyCode) {
        denied = true
      }
    })
  }

  return denied
}

const isEnvSet = Boolean(process.iblsEnv.IBLS_SENTRY_DSN)

export default class SentryHelper {
  static init() {
    if (isEnvSet) {
      Sentry.init({
        Vue,

        dsn: process.iblsEnv.IBLS_SENTRY_DSN,
        environment: process.iblsEnv.NODE_ENV,
        sendDefaultPii: true,

        denyUrls: ['yandex', 'google', /extensions\//i, /^chrome:\/\//i],

        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === 'xhr') {
            const { xhr } = hint
            const url = String(breadcrumb.data?.url ?? xhr?.responseURL)
            const denied = isDenied(url)

            if (denied) {
              return null
            }
          }

          return breadcrumb
        },

        beforeSend(event, hint) {
          const error = hint.originalException

          if (error.request) {
            const url = error.request.responseURL
            const code = error.request.status
            const denied = isDenied(url, code)

            if (denied) {
              return null
            }
          }

          return event
        },

        normalizeDepth: 5,

        // TODO: https://docs.sentry.io/platforms/javascript/guides/vue/sourcemaps/tools/webpack/
      })
    }
  }

  /**
   * @param {number} id
   * @param {string} email
   * @param {string} baseRole
   */
  static setUser(id, email, baseRole) {
    if (isEnvSet) {
      Sentry.setUser({
        id,
        email,
      })

      Sentry.setTag('baseRole', baseRole)
    }
  }

  static resetUser() {
    if (isEnvSet) {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }
}
