import Vue from 'vue'

/**
 * @param {string} message
 * @param {'success'|'error'} type
 */
export const showToast = (message, type = 'success') => {
  Vue.toasted.show(message, {
    duration: 3000,
    type,
  })
}
