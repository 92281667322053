import ClientMain from '@frontend/layouts/ClientMain.vue'
import { clientMainRoutes } from '@frontend/routes/client/clientMainRoutes'
import { clientSupportRoutes } from '@frontend/routes/client/clientSupportRoutes'
import UserStore from '@frontend/UserStore'

const checkRole = (to, from, next) => {
  const isClient = UserStore.store.getters['common/user/isClient']

  if (isClient) {
    next()
  } else {
    next({
      name: 'login',
    })
  }
}

export const clientRoutes = [
  {
    path: '/client',
    component: ClientMain,
    children: [...clientMainRoutes, ...clientSupportRoutes],
  },
]

clientRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
