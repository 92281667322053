export const clientMainRoutes = [
  {
    path: 'profile',
    name: 'client-profile',
    component: () =>
      import(
        /* webpackChunkName: "client-profile" */
        '@frontend/pages/showcase/client/Profile.vue'
      ),
  },

  {
    path: 'online-lessons',
    name: 'client-showcase-online-lessons',
    component: () =>
      import(
        /* webpackChunkName: "showcase-home" */
        '@frontend/pages/common/showcase/LessonsOnline.vue'
      ),
  },

  {
    path: 'invoices',
    name: 'client-showcase-invoices',
    component: () =>
      import(
        /* webpackChunkName: "showcase-invoices" */
        '@frontend/pages/common/showcase/Invoices.vue'
      ),
  },

  {
    path: 'invoice/:invoiceId(\\d+)',
    name: 'client-showcase-the-invoice',
    component: () =>
      import(
        /* webpackChunkName: "showcase-invoice" */
        '@frontend/pages/common/showcase/Invoice.vue'
      ),
  },

  {
    path: 'notifications',
    name: 'client-notifications',
    component: () =>
      import(
        /* webpackChunkName: "client-notifications" */
        '@frontend/pages/common/showcase/Notifications.vue'
      ),
  },
  {
    path: 'online-lessons/:lessonId(\\d+)',
    name: 'client-the-online-lesson',
    component: () =>
      import(
        /* webpackChunkName: "the-online-lesson" */
        '@frontend/pages/common/showcase/LessonsOnline.vue'
      ),
  },
]
