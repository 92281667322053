import main from '@frontend/store/showcase/student/main/store'
import profile from '@frontend/store/showcase/student/profile/store'

export default {
  namespaced: true,

  modules: {
    main,
    profile,
  },
}
