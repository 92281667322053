import ApiBase from 'ApiRest/ApiBase'

export const ApiCheckDocuments = new (class extends ApiBase {
  /**
   * Проверка наличия загруженных шаблонов
   *
   * @param {number} contractId
   * @returns {Promise<{documentTypeId: number, isTemplateExists: boolean}[]>}
   */
  get(contractId) {
    const url = `/enrollment/contract/${contractId}/check-documents`

    return this._GET(url)
  }
})()
