/**
 * @typedef {Object} Invoice
 * @property {string} amount
 * @property {string} createdDate
 * @property {number} id
 * @property {string} number
 * @property {Object} promocode
 * @property {boolean} canGenerateReceipt
 * @property {Object} service
 * @property {number} service.price
 * @property {number} service.id
 * @property {string} service.name
 * @property {Object} status
 * @property {number} status.id
 * @property {string} status.name
 * @property {Object} student
 * @property {number} student.id
 * @property {string} student.name
 * @property {string} url
 */

export default () => ({
  /**
   * @type {Invoice[]}
   */
  invoices: [],

  invoice: {
    id: null,
    number: null,
    amount: null,
    url: null,
    createdDate: null,
    status: null,
    service: null,
    student: null,
    canGenerateReceipt: false,
    isFamilyService: false,

    promocode: {
      id: null,
      code: null,
      bestBefore: null,
      status: null,

      group: {
        id: null,
        name: null,
        bestBefore: null,
        initialGeneratedCount: null,
        discount: null,
        newPromocodesCount: null,
        usedPromocodesCount: null,
        expiredPromocodesCount: null,
        discountType: null,
      },
    },
  },

  paymentUrl: '',
})
