import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setItems(state, payload) {
    Vue.set(state, 'items', payload)
  },

  /**
   * Используется при запросе событий после переключения учеников
   *
   * @param {Object} state
   * @param {number} year
   */
  setYear(state, year = new Date().getFullYear()) {
    state.year = year
  },
}
