<template>
  <header class="s-header s-header-client mb-0">
    <div class="s-logo s-header__logo">
      <h2 class="s-school-name">{{ schoolName }}</h2>

      <router-link :to="{ name: 'login' }">
        <img :src="imageLogo" :alt="$t('layout.guest.logotype')" />
      </router-link>
    </div>
  </header>
</template>

<script>
import imageLogo from 'Static/images/@frontend/common-lk/logo.svg'

export default {
  name: 'TheHeaderGuest',

  props: {
    schoolName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      imageLogo,
    }
  },
}
</script>
