import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonJoin = new (class extends ApiBase {
  /**
   * Получить ссылку для входа в конференцию
   *
   * @param {number} onlineLessonId
   * @returns {Promise<string>}
   */
  get(onlineLessonId) {
    return this._GET(`/account/student/online-lessons/${onlineLessonId}/join`)
  }
})()
