import SentryHelper from 'Helpers/SentryHelper'
import { WebAnalyticsHelper } from 'Helpers/WebAnalyticsHelper'

export const auth = {
  /**
   * Пользователь вошел в систему
   *
   * @param {{}} e
   * @param {{number}} e.id
   * @param {{string}} e.email
   * @param {{string}} e.role
   */
  userLoggedIn({ id, email, role }) {
    // TODO: так же передавать в sentry данные менеджера когда будет API получения данных текущего пользователя админки
    SentryHelper.setUser(id, email, role)
  },

  /**
   * Пользователь вышел из системы
   */
  userLoggedOut() {
    SentryHelper.resetUser()
  },

  /**
   * Принудительный разлогин после смены пароля
   */
  userLoggedOutAfterPasswordChange() {
    SentryHelper.resetUser()
  },

  /**
   * Клиент зарегистрировался
   */
  clientCreated() {
    WebAnalyticsHelper.goalRegistration()
  },

  /**
   * Клиент зарегистрировался как лид
   */
  leadCreated() {
    WebAnalyticsHelper.goalRegistrationDemo()
  },
}
