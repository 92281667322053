var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "s-footer" }, [
    _c("div", { staticClass: "text-secondary" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("layout.guest.intelligent_technologies")) +
          "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }