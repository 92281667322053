import {
  formatItem as formatCalendarItem,
  types as calendarItemTypes,
} from 'Helpers/Calendar'

export default {
  /**
   * Онлайн-уроки без темы не должны отображаться в ЛК ученика
   *
   * @param {{
   *   items: CalendarItemI[]
   * }} state
   * @returns {CalendarItemI[]}
   */
  events(state) {
    return state.items
      .filter(
        (item) =>
          !(
            item?.type?.id === calendarItemTypes.ONLINE_LESSON &&
            item?.name === ''
          ),
      )
      .map((item) => formatCalendarItem(item))
  },
}
