import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportQuestionComment = new (class extends ApiBase {
  /**
   * @param {number} questionId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(questionId, payload, parameters) {
    const url = `/support/questions/${questionId}/comment?${UrlHelper.param(
      parameters,
    )}`

    return this._POST(url, payload)
  }

  /**
   * @param {number} questionId
   * @param {number} commentId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  patch(questionId, commentId, payload, parameters) {
    const url = `/support/questions/${questionId}/comments/${commentId}?${UrlHelper.param(
      parameters,
    )}`

    return this._PATCH(url, payload)
  }
})()
