import { ApiShowcasePurchase } from 'ApiRest/Api/Showcase/Purchase'
import { ApiShowcasePurchases } from 'ApiRest/Api/Showcase/Purchases'

export default {
  async fetchPurchases({ commit }) {
    const { data } = await ApiShowcasePurchases.get()

    commit('setPurchases', data)
  },

  async fetchPurchase({ commit, state }) {
    const { data } = await ApiShowcasePurchase.get(state.purchaseId)

    commit('setPurchase', data)
  },
}
