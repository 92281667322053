export default {
  // Наши Пенаты
  1: {
    metrika: {
      id: 52361578,
      init: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    },
    idAnalytics: 'UA-211303919-1',
    roistatId: '2741549c8a3d679fffa68bbc7e770a90',
  },
  // Олимп
  4: {
    metrika: {
      id: 52361578,
      init: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    },
    idAnalytics: 'UA-211303919-1',
    roistatId: '2741549c8a3d679fffa68bbc7e770a90',
  },
  // Васильевский лицей
  35: {
    metrika: {
      id: 89158109,
      init: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true,
      },
    },
  },
  // Корифей
  33: {
    metrika: {
      id: 57324862,
      init: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      },
    },
  },
  // Интеллект-плюс
  30: {
    metrika: {
      id: 65598970,
      init: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    },
  },
}
