import ApiBase from 'ApiRest/ApiBase'

export default new (class FeCertComplete extends ApiBase {
  /**
   * Получить аттестацию СО
   *
   * @returns {Promise}
   */
  put() {
    return this._PUT(`/account/student/test/fe-cert-complete`)
  }
})()
