import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setFAQItems(state, payload) {
    state.FAQItems = payload
  },

  setActiveItemId(state, payload) {
    state.activeItemId = payload
  },
}
