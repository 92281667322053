export default () => ({
  /** @type {ShowcaseBanner[]} */
  banners: [],

  /** @type {ShowcaseProduct[]} */
  productsLatest: [],

  /** @type {ShowcaseProduct[]} */
  productsPopular: [],
})
