import Constants from 'Constants'

const { FULL_TIME, EXTRAMURAL, FAMILY } = Constants.educationType

export const mainTabsClient = {
  // TODO: Временное скрытие https://redmine.ibls.tech/issues/106
  // analytics: {
  //   id: 'analytics',
  //   label: 'Аналитика',
  //   educationTypes: [EXTRAMURAL, FAMILY],
  // },

  statement: {
    id: 'statement',
    label: 'Ведомость',
  },

  average: {
    id: 'average',
    label: 'Средний балл',
    educationTypes: [FULL_TIME],
  },

  homework: {
    id: 'homework',
    label: 'Задания',
    educationTypes: [FULL_TIME],
  },

  journal: {
    id: 'journal',
    label: 'Дневник',
    educationTypes: [FULL_TIME],
  },

  calendar: {
    id: 'calendar',
    label: 'Календарь',
  },

  certification: {
    id: 'certification',
    label: 'Аттестация',
    educationTypes: [FAMILY],
  },

  schedule: {
    id: 'schedule',
    label: 'Уроки',
    educationTypes: [EXTRAMURAL],
  },

  goals: {
    id: 'goals',
    label: 'Цели',
    educationTypes: [EXTRAMURAL],
  },

  // notifications: {
  //   id: 'notifications',
  //   label: 'Уведомления'
  // }
}

export const mainTabsStudent = {
  subjects: {
    id: 'subjects',
    label: 'Предметы',
  },

  physicon: {
    id: 'physicon',
    label: 'Физикон',
  },

  calendar: {
    id: 'calendar',
    label: 'Календарь',
  },

  schedule: {
    id: 'schedule',
    label: 'Уроки',
    educationTypes: [EXTRAMURAL, FAMILY],
  },

  homework: {
    id: 'homework',
    label: 'Задания',
    educationTypes: [FULL_TIME],
  },

  journal: {
    id: 'journal',
    label: 'Дневник',
    educationTypes: [FULL_TIME],
  },

  goals: {
    id: 'goals',
    label: 'Цели',
  },
}
