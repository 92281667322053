var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      ref: "header",
      staticClass: "s-header-wrapper",
      class: { noticed: _vm.noticeText },
    },
    [
      _vm.noticeText
        ? _c("div", { staticClass: "s-header__notice" }, [
            _c("span", [
              _vm._v("\n      " + _vm._s(_vm.noticeText) + "\n\n      "),
              _c("span", { staticClass: "notice-text--thin" }, [
                _vm._v(_vm._s(_vm.noticeAdditionalMessage)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "s-header", class: _vm.classHeaderClient },
        [
          _c(
            "router-link",
            { staticClass: "s-logo s-header__logo", attrs: { to: _vm.toMain } },
            [
              _c("h2", { staticClass: "s-school-name" }, [
                _vm._v(_vm._s(_vm.schoolName)),
              ]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: _vm.imageLogo,
                  alt: _vm.$t("layout.common.logotype"),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "s-header__profile d-lg-none d-xl-flex" },
            [
              _c("ElAvatar", {
                attrs: {
                  abbreviation: _vm.abbreviation,
                  image: _vm.avatar,
                  shape: "circle",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.fullName)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.students.length
            ? _c(
                "div",
                { staticClass: "s-header__student-select" },
                [
                  _c("v-select", {
                    staticClass: "v-select-student",
                    attrs: {
                      value: _vm.currentStudentFormatted,
                      options: _vm.studentsFormatted,
                      clearable: false,
                      searchable: false,
                      label: "abbr",
                    },
                    on: { input: _vm.selectStudent },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function (ref) {
                            var abbr = ref.abbr
                            var name = ref.name
                            var gradeName = ref.gradeName
                            var photo = ref.photo
                            return [
                              _c(
                                "div",
                                { staticClass: "s-header__student" },
                                [
                                  _c("ElAvatar", {
                                    attrs: {
                                      abbreviation: abbr,
                                      image: _vm.getStudentAvatar(photo),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(name)),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "grade" }, [
                                      _vm._v(_vm._s(gradeName)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "option",
                          fn: function (ref) {
                            var name = ref.name
                            var gradeName = ref.gradeName
                            return [
                              _c(
                                "div",
                                { staticClass: "s-header__student-option" },
                                [
                                  _c("b", { staticClass: "name" }, [
                                    _vm._v(_vm._s(name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "t-tag" }, [
                                    _vm._v(_vm._s(gradeName)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3868103365
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.rating
            ? _c("TheHeaderRating", { attrs: { rating: _vm.rating } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "nav",
            { staticClass: "s-header__nav" },
            [
              _vm.notifications
                ? _c(
                    "router-link",
                    { attrs: { to: { name: "client-notifications" } } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.bell,
                          alt: _vm.$t("layout.common.notification_icon"),
                        },
                      }),
                      _vm._v(" "),
                      Number(_vm.notifications.notifications) > 0
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.notifications.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.calendar
                ? _c(
                    "router-link",
                    { attrs: { to: { name: "client-calendar" } } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.calendar,
                          alt: _vm.$t("layout.common.calendar_icon"),
                        },
                      }),
                      _vm._v(" "),
                      Number(_vm.calendar.notifications) > 0
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.calendar.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.messages
                ? _c(
                    "router-link",
                    {
                      staticClass: "ml-20",
                      attrs: { to: { name: "client-messages" } },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.message,
                          alt: _vm.$t("layout.common.message_icon"),
                        },
                      }),
                      _vm._v(" "),
                      Number(_vm.messages.notifications) > 0
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.messages.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasInvoices
                ? _c(
                    "router-link",
                    { staticClass: "ml-20", attrs: { to: _vm.invoicesLink } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.payments,
                          alt: _vm.$t("layout.common.bill_icon"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "wide",
                  attrs: { to: { name: "client-profile" } },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.icons.settings,
                      alt: _vm.$t("layout.common.settings_icon"),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "wide", attrs: { to: { name: "logout" } } },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.icons.exit,
                      alt: _vm.$t("layout.common.exit_icon"),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "s-mobile-menu__toggle",
              class: { opened: _vm.opened },
              on: { click: _vm.toggleMobileMenu },
            },
            _vm._l(3, function (i) {
              return _c("span", { key: i })
            }),
            0
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.opened
              ? _c(
                  "menu",
                  {
                    staticClass: "s-mobile-menu",
                    style: _vm.mobileMenuTopOffset,
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "block" },
                      _vm._l(_vm.links, function (link) {
                        return _c(
                          "router-link",
                          {
                            key: link.link.name,
                            staticClass: "link-dark",
                            attrs: { to: link.link },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(link.label) +
                                "\n\n            "
                            ),
                            Number(link.notifications) > 0
                              ? _c("i", { staticClass: "notif" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(link.notifications) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "link-dark",
                            attrs: { to: { name: "client-profile" } },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("layout.common.settings")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "link-dark",
                            attrs: { to: { name: "logout" } },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("layout.common.log_off")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }