export default {
  setInvoices(state, payload) {
    state.invoices = payload
  },

  setTheInvoice(state, payload) {
    state.invoice = payload
  },

  setTheInvoicePaymentUrl(state, payload) {
    state.paymentUrl = payload
  },
}
