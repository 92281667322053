import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef {{
 * isActive:boolean,
 * isSubscribed:boolean,
 * payload:null|T,
 * }} NotificationChannelI<T>
 */

export default new (class extends ApiBase {
  /**
   * @returns {Promise<{
   * telegram:NotificationChannelI<TelegramPayloadI>,
   * }>}
   */
  get() {
    const url = `/notification/map`

    return this._GET(url)
  }
})()
