import UserStoreShowcase from '@frontend/UserStoreShowcase'

const checkAuth = (to, from, next) => {
  const isAuthorized =
    UserStoreShowcase.store.getters['common/user/isAuthorized']

  if (isAuthorized) {
    next('/')
  } else {
    next()
  }
}

/**
 * Некоторые страницы авторизации были перенесены в отдельную репу (ibls-widgets).
 * Следующие роуты больше не обрабатываются приложением:
 * - login
 * - logout
 * - password-reset
 * - password-reset-confirm
 */

export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "login" */
        '@frontend/pages/auth/Login.vue'
      ),
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/create-password',
    // TODO: В e-mail указывается ссылка с этим путем.
    //  При изменении согласовать с бэком.
    name: 'password-create',
    component: () =>
      import(
        /* webpackChunkName: "password-create" */
        '@frontend/pages/auth/PasswordCreate.vue'
      ),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */
        '@frontend/pages/auth/PasswordReset.vue'
      ),
  },
  {
    path: '/change-password',
    // TODO: В e-mail указывается ссылка с этим путем.
    //  При изменении согласовать с бэком.
    name: 'password-reset-confirm',
    component: () =>
      import(
        /* webpackChunkName: "password-reset-confirm" */
        '@frontend/pages/auth/PasswordResetConfirm.vue'
      ),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () =>
      import(
        /* webpackChunkName: "registration" */
        '@frontend/pages/auth/Registration.vue'
      ),
  },
  /*   {
    path: '/demo-registration',
    name: 'demo-registration',
    component: () =>
      import(
        /!* webpackChunkName: "demo-registration" *!/
        '@frontend/pages/auth/DemoRegistration.vue'
      ),
  },
  {
    path: '/educont-registration',
    name: 'educont-registration',
    component: () =>
      import(
        /!* webpackChunkName: "educont-registration" *!/
        '@frontend/pages/auth/EducontRegistration.vue'
      ),
  }, */
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () =>
      import(
        /* webpackChunkName: "confirm-email" */
        '@frontend/pages/auth/ConfirmEmail.vue'
      ),
  },
]

authRoutes.forEach((route) => {
  if (route.name !== 'password-create') {
    route.beforeEnter = checkAuth
  }
})
