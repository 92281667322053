import authHelper from '@frontend/helpers/auth'
import { scrollBehavior } from '@frontend/helpers/scrollBehavior'
import NotFound from '@frontend/pages/NotFound.vue'
import { authRoutes } from '@frontend/routes/showcase/auth'
import { clientRoutes } from '@frontend/routes/showcase/client/clientRoutes'
import { guestRoutes } from '@frontend/routes/showcase/guest/guestRoutes'
import { studentRoutes } from '@frontend/routes/showcase/student/studentRoutes'
import UserStoreShowcase from '@frontend/UserStoreShowcase'
import { ApiCreateClientStudent } from 'ApiRest/Api/Account/Client/CreateClientStudent'
import { ApiIsAuthorized } from 'ApiRest/Api/Auth/IsAuthorized'
import { authUrl } from 'Helpers/const/authUrl'
import { redirectToAdminArea, redirectToAuth } from 'Helpers/redirectHelper'
import { isUndefined } from 'lodash'
import Router from 'vue-router'

export default new (class UserRouterShowcase {
  constructor() {
    this.router = null
  }

  init() {
    this.router = new Router({
      mode: 'history',

      routes: [
        ...authRoutes,
        ...clientRoutes,
        ...guestRoutes,
        ...studentRoutes,

        {
          path: '*',
          name: 'not-found',
          component: NotFound,
        },
      ],

      scrollBehavior,
    })

    /**
     * Разлогинить юзера
     *
     * @param {string} targetUrl
     * @returns {Promise}
     */
    const logout = async (targetUrl) => {
      await UserStoreShowcase.store.dispatch('auth/login/logout')

      return redirectToAuth(authUrl.login, targetUrl)
    }

    /**
     * Открыть раздел клиента
     *
     * @param {Function} next
     * @returns {Function}
     */
    const displayClientSection = (next) =>
      next({
        name: 'client-showcase',
      })

    /**
     * Открыть раздел ученика
     *
     * @param {Function} next
     * @returns {Function}
     */
    const displayStudentSection = (next) =>
      next({
        name: 'student-showcase',
      })

    /**
     * Открыть раздел гостя
     *
     * @param {Function} next
     * @returns {Function}
     */
    const displayGuestUserSection = (next) =>
      next({
        name: 'guest-showcase',
      })

    const isClient = () =>
      UserStoreShowcase.store.getters['common/user/isClient']
    const isStudent = () =>
      UserStoreShowcase.store.getters['common/user/isStudent']
    const isManager = () =>
      UserStoreShowcase.store.getters['common/user/isManager']
    const isGuest = () =>
      UserStoreShowcase.store.getters['common/user/isAnonymous']

    /**
     * @param {Object} to
     * @param {Function} next
     * @returns {Function}
     */
    const displayCurrentRoleShowcase = (to, next) => {
      const prefixes = new Map([
        ['client', 'client-showcase'],
        ['student', 'student-showcase'],
        ['guest', 'guest-showcase'],
      ])

      let prefix

      prefixes.forEach((routePrefix) => {
        if (to.name.startsWith(routePrefix)) {
          prefix = routePrefix
        }
      })

      /**
       * @param {string} role
       * @returns {Function}
       */
      const nextFor = (role) =>
        next({
          name: `${prefixes.get(role)}${to.name.substring(prefix.length)}`,
          params: to.params,
          replace: true,
        })

      if (prefix !== undefined) {
        if (isClient() && prefix !== prefixes.get('client')) {
          return nextFor('client')
        }

        if (isStudent() && prefix !== prefixes.get('student')) {
          return nextFor('student')
        }

        if (isGuest() && prefix !== prefixes.get('guest')) {
          return nextFor('guest')
        }
      }

      return next()
    }

    /**
     * Проверки авторизации
     *
     * @param {Object} to
     * @param {Function} next
     * @returns {Promise<boolean|void>}
     */
    const processIsAuthorized = async (to, next) => {
      /**
       * @type {{baseRole}} data
       */
      const { data } = await ApiIsAuthorized.post()

      if (data === null) {
        UserStoreShowcase.store.commit('common/user/resetState', null, {
          root: true,
        })

        // Если страница регистрации, пропустить
        if (authHelper.auth.isAuthPage(to.name)) {
          return next()
        }
      }

      UserStoreShowcase.store.commit(
        'common/user/setUserValue',
        {
          key: 'role',
          value: data?.baseRole ?? 'anonymous',
        },
        {
          root: true,
        },
      )

      return true
    }

    const setTargetUrl = (to) => {
      if (
        to.name === 'login' ||
        to.name === 'logout' ||
        to.name === 'not-found'
      ) {
        return window.location.origin
      }

      return window.location.href
    }

    /**
     * @param {{
     *   query: {
     *     smartcode: string,
     *     targetUrl: string,
     *     try_again: string
     *   },
     *   name: string,
     *   path: string,
     *   params: Object,
     * }} to
     * @param {{name: string}} from
     * @param {Function} next
     * @returns {Promise}
     */
    const processBeforeEach = async (to, from, next) => {
      const targetUrl = setTargetUrl(to)

      // Если переход на страницу login/logout произошел через роутинг (router-link, router.push()),
      // то делать редирект на authUrl

      if (to.name === 'logout') {
        return logout(targetUrl)
      }

      await processIsAuthorized(to, next, targetUrl)

      // Если не авторизован и страница авторизации - пропустить
      if (authHelper.auth.isAuthPage(to.name) && isGuest()) {
        return next()
      }

      if (isManager()) {
        return redirectToAdminArea()
      }

      if (
        from === Router.START_LOCATION ||
        authHelper.auth.isAuthPage(to.name)
      ) {
        if (isClient()) {
          await UserStoreShowcase.store.dispatch(
            'client/profile/fetchProfile',
            null,
            {
              root: true,
            },
          )

          await ApiCreateClientStudent.post()
        }

        if (isStudent()) {
          await UserStoreShowcase.store.dispatch(
            'student/profile/fetchProfile',
            null,
            {
              root: true,
            },
          )
        }
      }

      if (isUndefined(to.name)) {
        if (isClient()) {
          return displayClientSection(next)
        }

        if (isStudent()) {
          return displayStudentSection(next)
        }

        if (isGuest()) {
          return displayGuestUserSection(next)
        }
      } else {
        return displayCurrentRoleShowcase(to, next)
      }

      return logout(targetUrl)
    }

    this.router.beforeEach(processBeforeEach)

    return this.router
  }
})()
