import ApiMessages from 'ApiRest/Api/User/Messages'

/**
 * @param {'client' | 'student'} user
 * @returns {any}
 */
export default (user) => ({
  async fetchMessages({ commit }) {
    const { data } = await ApiMessages.get()

    commit(
      `${user}/main/setNotificationsCount`,
      {
        target: `${user}-messages`,
        count: data.countUnreadMessages,
      },
      {
        root: true,
      },
    )
  },

  startPollingMessages({ dispatch, commit }) {
    dispatch('fetchMessages')

    const pollingId = setInterval(() => {
      dispatch('fetchMessages')
    }, 5000)

    commit('setPollingId', pollingId)
  },

  stopPollingMessages({ state, commit }) {
    clearInterval(state.pollingId)
    commit('unsetPollingId')
  },
})
