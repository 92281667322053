export const clientSubjectRoutes = [
  {
    path: 'subjects',
    name: 'client-showcase-subjects',
    component: () =>
      import(
        /* webpackChunkName: "client-showcase-subjects" */
        '@frontend/pages/common/showcase/subject/Subjects.vue'
      ),
  },

  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)',
    name: 'client-showcase-subject',
    component: () =>
      import(
        /* webpackChunkName: "client-showcase-subject" */
        '@frontend/pages/common/showcase/subject/Subject.vue'
      ),
  },

  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/bibliography',
    name: 'client-showcase-subject-bibliography',
    component: () =>
      import(
        /* webpackChunkName: "client-showcase-subject-bibliography" */
        '@frontend/pages/common/showcase/subject/SubjectBibliography.vue'
      ),
  },

  {
    path:
      'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/lesson/:lessonId(\\d+)/:homeworkSolutionId(\\d+)?',
    name: 'client-showcase-subject-lesson',
    component: () =>
      import(
        /* webpackChunkName: "client-showcase-subject-lesson" */
        '@frontend/pages/common/showcase/subject/Lesson.vue'
      ),
  },
]
