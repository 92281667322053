import { getModuleSettingValue, hasModules } from 'Helpers/accessControl/module'

export default {
  hasModules(state) {
    return (moduleIdsSearched) =>
      hasModules(state.activeModules, moduleIdsSearched)
  },

  moduleSettingValue(state) {
    return (moduleId, settingId) =>
      getModuleSettingValue({
        activeModules: state.activeModules,
        moduleId,
        settingId,
      })
  },
}
