import ApiSystemTimeStamp from 'Api/system/Timestamp'
import { ApiShowcaseProduct } from 'ApiRest/Api/Showcase/Product'
import { ApiShowcaseProductOffer } from 'ApiRest/Api/Showcase/Product/Offer'
import { ApiShowcaseProductOptionsStudents } from 'ApiRest/Api/Showcase/Product/Students'

export default {
  async fetchProduct({ state, commit }) {
    const { data } = await ApiShowcaseProduct.get(state.productId)

    commit('setProduct', data)
  },

  async fetchProductOptions({ commit, state }) {
    const { data } = await ApiShowcaseProductOffer.get(state.productId, {
      user: {
        id: state.user.id,
      },
    })

    commit('setOffers', data)

    if (data.length > 0) {
      commit('setOffer', data[0])
    }
  },

  async fetchProductOptionsGuest({ commit, state }) {
    const { data } = await ApiShowcaseProductOffer.get(state.productId)

    commit('setOffers', data)

    if (data.length > 0) {
      commit('setOffer', data[0])
    }
  },

  async fetchUsers({ commit, state }) {
    const { data } = await ApiShowcaseProductOptionsStudents.get(
      state.productId,
    )

    commit('setUsers', data)

    if (data.length > 0) {
      commit('setUser', data[0])
    }
  },

  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiSystemTimeStamp.get()

    commit('setServerTimestamp', data)
  },
}
