import ApiBase from 'ApiRest/ApiBase'

export default new (class OnCheck extends ApiBase {
  /**
   * Перевести Ответ в статус “На проверке“ (ответить на вопрос)
   *
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  put(id, data) {
    return this._PUT(`/account/student/test/answers/${id}/on-check`, data)
  }
})()
