var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "s-header s-header-client mb-0" }, [
    _c(
      "div",
      { staticClass: "s-logo s-header__logo" },
      [
        _c("h2", { staticClass: "s-school-name" }, [
          _vm._v(_vm._s(_vm.schoolName)),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: { name: "login" } } }, [
          _c("img", {
            attrs: { src: _vm.imageLogo, alt: _vm.$t("layout.guest.logotype") },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }