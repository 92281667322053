import { WebAnalyticsHelper } from 'Helpers/WebAnalyticsHelper'

export const demoAccess = {
  /**
   * Создан демо-ученик для клиента
   */
  studentCreated() {
    WebAnalyticsHelper.goalDemoAccessForm()
  },
}
