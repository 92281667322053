import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class extends ApiBase {
  /**
   * Список событий
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/account/student/simple-events?${UrlHelper.param(parameters)}`,
    )
  }
})()
