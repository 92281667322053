import Constants from 'Constants'

import { i18n } from '@/i18n/setup'

const StatusNamePathById = {
  [Constants.enrollmentContractStatus.REQUEST_PENDING]:
    'const.enrollment.contract.status.request_pending',
  [Constants.enrollmentContractStatus.REQUEST_FIX_REQUIRED]:
    'const.enrollment.contract.status.request_fix_required',
  [Constants.enrollmentContractStatus.REQUEST_REJECTED]:
    'const.enrollment.contract.status.request_rejected',
  [Constants.enrollmentContractStatus.REQUEST_REMOVED_BY_CLIENT]:
    'const.enrollment.contract.status.request_removed_by_client',
  [Constants.enrollmentContractStatus.REQUEST_WITHDRAWN_BY_CLIENT]:
    'const.enrollment.contract.status.request_withdrawn_by_client',
  [Constants.enrollmentContractStatus.REQUEST_APPROVED]:
    'const.enrollment.contract.status.request_approved',
  [Constants.enrollmentContractStatus.AWAITING_SIGNATURE]:
    'const.enrollment.contract.status.awaiting_signature',
  [Constants.enrollmentContractStatus.SIGNED]:
    'const.enrollment.contract.status.signed',
  [Constants.enrollmentContractStatus.COMPLETED]:
    'const.enrollment.contract.status.completed',
  [Constants.enrollmentContractStatus.TERMINATED]:
    'const.enrollment.contract.status.terminated',
}

export const EnrollmentStatusHelper = {
  getStatusNamePath(status) {
    return StatusNamePathById[status.id] ?? null
  },

  replaceStatusNameWithPath(items = []) {
    return items.map((item) => {
      const path = StatusNamePathById[item.status.id]
      const name = path ? i18n.t(path) : item.status.name

      return {
        ...item,
        status: {
          ...item.status,
          name,
        },
      }
    })
  },

  replaceStatusOptionNameWithPath(options = []) {
    return options.map((option) => {
      const path = StatusNamePathById[option.id]
      const name = path ? i18n.t(path) : option.name

      return {
        ...option,
        name,
      }
    })
  },
}
