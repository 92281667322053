import actions from '@frontend/store/showcase/common/actions'
import cart from '@frontend/store/showcase/common/cart/store'
import catalog from '@frontend/store/showcase/common/catalog/store'
import collection from '@frontend/store/showcase/common/collection/store'
import getters from '@frontend/store/showcase/common/getters'
import home from '@frontend/store/showcase/common/home/store'
import invoices from '@frontend/store/showcase/common/invoices/store'
import lessons from '@frontend/store/showcase/common/lessons/store'
import lessonsOnline from '@frontend/store/showcase/common/lessonsOnline/store'
import mutations from '@frontend/store/showcase/common/mutations'
import notifications from '@frontend/store/showcase/common/notifications/store'
import product from '@frontend/store/showcase/common/product/store'
import purchases from '@frontend/store/showcase/common/purchases/store'
import state from '@frontend/store/showcase/common/state'
import subjects from '@frontend/store/showcase/common/subjects/store'
import support from '@frontend/store/showcase/common/support/store'
import test from '@frontend/store/showcase/common/test/store'
import user from '@frontend/store/showcase/common/user/store'

export default {
  namespaced: true,

  modules: {
    cart,
    catalog,
    collection,
    home,
    invoices,
    lessons,
    lessonsOnline,
    notifications,
    product,
    purchases,
    subjects,
    support,
    test,
    user,
  },

  state,
  getters,
  mutations,
  actions,
}
