var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("LoaderFullScreen")
    : _c(
        "LayoutBase",
        {
          attrs: {
            "is-loading-switch-student": _vm.isLoadingSwitchStudent,
            main: _vm.main,
            noticed: _vm.isCurrentStudentDemo,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("TheHeaderClient", {
                    attrs: {
                      abbreviation: _vm.nameAbbreviation,
                      avatar: _vm.avatar,
                      "current-student": _vm.currentStudent,
                      "full-name": _vm.fullNameWithShortLastName,
                      "has-contracts-or-drafts": _vm.hasContracts,
                      "has-enrolled-students": _vm.hasEnrolledStudents,
                      links: _vm.getLinks,
                      "notice-additional-message":
                        _vm.getCurrentStudentSchoolDemoMessage,
                      "notice-text": _vm.demoAccessTimeText,
                      rating: _vm.studentRating,
                      "school-name": _vm.schoolName,
                      students: _vm.enrolledStudents,
                    },
                    on: { "select-student": _vm.currentStudentChange },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "aside",
              fn: function () {
                return [
                  _vm.hasLinks
                    ? _c("TheAside", {
                        attrs: {
                          links: _vm.getLinks,
                          "user-settings": _vm.userSettings,
                        },
                        on: { "toggle-aside": _vm.toggleAside },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "tabs-nav",
              fn: function () {
                return [
                  _c("TabsNav", {
                    attrs: {
                      "current-tab": _vm.currentTab,
                      tabs: _vm.mainTabsFiltered,
                    },
                    on: { change: _vm.onChangeTabNav },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("TheFooter", {
                    attrs: { school: _vm.school, "user-id": _vm.userId },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { mode: "out-in", name: "fade" } },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _vm._v(" "),
          _c("BtnUp"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }