import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Получить Очный Предварительный-Тест по ID
   *
   * @param ftePreliminaryTestId
   * @returns {Promise}
   */
  get(ftePreliminaryTestId) {
    return this._GET(
      `/account/student/test/preliminary-tests/${ftePreliminaryTestId}`,
    )
  }
})()
