import ApiUpload from 'Api/Upload'
import ApiBase from 'ApiRest/ApiBase'

export const ApiProfilePhoto = new (class extends ApiBase {
  /**
   * Загрузка аватара
   *
   * @param {File} file
   * @returns {Promise}
   */
  post(file) {
    const url = `/account/profile/photo`

    return ApiUpload.sendPhoto(url, file)
  }

  delete() {
    const url = `/account/profile/photo`

    return this._DELETE(url)
  }
})()
