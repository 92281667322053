import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setLessons(state, lessons) {
    state.lessons = lessons
  },

  setTheLesson(state, theLesson) {
    state.theLessonCached[theLesson.id] = theLesson
  },
}
