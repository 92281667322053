import changeLocal from './changeLocal/store'
import demoRegistration from './demoRegistration/store'
import educontLogin from './educontLogin/store'
import educontRegistration from './educontRegistration/store'
import login from './login/store'
import passwordCreate from './passwordCreate/store'
import passwordReset from './passwordReset/store'
import registration from './registration/store'

export default {
  namespaced: true,

  modules: {
    demoRegistration,
    educontRegistration,
    educontLogin,
    registration,
    login,
    passwordReset,
    passwordCreate,
    changeLocal,
  },
}
