import Constants from 'Constants'
import { addWeeks, format, isSameDay, isSameWeek, parseISO } from 'date-fns'
import dateFormatHelper from 'Helpers/dateFormatHelper'
import { cloneDeep } from 'lodash'

const today = new Date()

/**
 * Общие геттеры целей
 *
 * @param {Object[]} goals
 * @returns {Object[]}
 */
const sortedGoalsPreview = (goals) => {
  const now = Date.now()

  return goals
    .map((goal) => {
      goal.comparingDate = Math.abs(
        now - parseISO(dateFormatHelper.toIso(goal.date)).getTime(),
      )

      return goal
    })
    .sort((a, b) => {
      if (a.comparingDate > b.comparingDate) {
        return 1
      }

      if (a.comparingDate < b.comparingDate) {
        return -1
      }

      return 0
    })
}

export const goalsGetters = {
  getGoalsNotCompleted(state) {
    return state.goals.filter((goal) => !goal.isReached)
  },

  getGoalsCompleted(state) {
    return state.goals.filter((goal) => goal.isReached)
  },

  getGoalsCompletedPreview(state, getters) {
    const sortedGoals = sortedGoalsPreview(getters.getGoalsCompleted)

    return sortedGoals.slice(0, 3).filter(Boolean)
  },

  getGoalsNotCompletedPreview(state, getters) {
    const sortedGoals = sortedGoalsPreview(getters.getGoalsNotCompleted)

    return sortedGoals.slice(0, 3).filter(Boolean)
  },
}

/**
 * @description общие геттеры уроков
 */
export const lessonsGetters = {
  getLessonsSortedByDay() {
    return (lessons) => {
      const result = {}

      lessons.forEach((lesson) => {
        const formattedDate = format(
          parseISO(dateFormatHelper.toIso(lesson.date)),
          'yyyy-MM-dd',
        )

        if (result[formattedDate]) {
          result[formattedDate].lessons.push(lesson)
        } else {
          result[formattedDate] = {
            date: lesson.date,
            lessons: [lesson],
          }
        }
      })

      return result
    }
  },

  getLessonsToday(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter((lesson) =>
        isSameDay(parseISO(dateFormatHelper.toIso(lesson.date)), today),
      ),
    )
  },

  getLessonsThisWeek(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter(
        (lesson) =>
          !isSameDay(parseISO(dateFormatHelper.toIso(lesson.date)), today, {
            weekStartsOn: 1,
          }) &&
          isSameWeek(parseISO(dateFormatHelper.toIso(lesson.date)), today, {
            weekStartsOn: 1,
          }),
      ),
    )
  },

  getLessonsNextWeek(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter((lesson) =>
        isSameWeek(
          parseISO(dateFormatHelper.toIso(lesson.date)),
          addWeeks(today, 1),
          {
            weekStartsOn: 1,
          },
        ),
      ),
    )
  },

  getLessonsNotPassed(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter((lesson) => !lesson.isPassed),
    )
  },

  getLessonsAll(state, getters) {
    return getters.getLessonsSortedByDay(state.lessons)
  },

  getLessonsTests(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter(
        (lesson) => lesson.type.id === Constants.lessonType.TESTING,
      ),
    )
  },

  getLessonsByTypeAndSubject(state, getters) {
    return ({ type, subject = 'Все' }) => {
      let result = {}

      if (type === 'notPassed') {
        result = cloneDeep(getters.getLessonsNotPassed)
      } else if (type === 'test') {
        result = cloneDeep(getters.getLessonsTests)
      } else {
        result = cloneDeep(getters.getLessonsAll)
      }

      if (subject !== 'Все') {
        Object.keys(result).forEach((day) => {
          result[day].lessons = result[day].lessons.filter(
            (lesson) => lesson.subject.name === subject,
          )

          if (!result[day].lessons.length) {
            delete result[day]
          }
        })
      }

      return result
    }
  },
}

export default {
  goalsGetters,
  lessonsGetters,
}
