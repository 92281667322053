import { flatten } from 'flat'

/**
 *
 * Создает список ошибок, возвращенных сервером.
 * Объект ошибок выравнивается, благодаря "flat" (https://www.npmjs.com/package/flat).
 * Пример использования:
 * .catch((error) => {
      Vue.toasted.show(getErrorsList(error), {
        type: 'error'
    })
 *
 * @param {Object} error
 * @returns {HTMLUListElement}
 */
const getErrorsList = (error) => {
  const errorsObj = flatten(error?.response?.data)
  const errorsArr = Object.values(errorsObj)
  const errorsList = document.createElement('ul')

  errorsArr.forEach((err) => {
    const li = document.createElement('li')

    li.textContent = err
    errorsList.appendChild(li)
  })

  return errorsList
}

export default getErrorsList
