import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  readNotificationSet(state, notification) {
    const index = state.notifications.findIndex(
      ({ id }) => id === notification.id,
    )

    if (index) {
      Vue.set(state.notifications, index, notification)
    }
  },

  notificationsSet(state, payload) {
    state.notifications = payload
  },
}
