<template>
  <footer class="s-footer">
    <div class="text-secondary">
      {{ $t('layout.guest.intelligent_technologies') }}
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
}
</script>
