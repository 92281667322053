import { isAfter } from 'date-fns'

export default {
  hasVacantSeats(state) {
    return state.product?.vacantSeats > 0
  },

  dateStart(state) {
    if (state.product) {
      const { dateStart, timeStart } = state.product.holding
      const dateTime = [dateStart, timeStart].join('T')

      return new Date(dateTime)
    }

    return new Date()
  },

  dateServer(state) {
    return new Date(state.serverTimestamp * 1000)
  },

  isEnrollPaused(state, getters) {
    return isAfter(getters.dateServer, getters.dateStart)
  },
}
