export const insertTrackersInDOM = function ({
  metrika = null,
  idGTM = null,
  idAnalytics = null,
  roistatId = null,
}) {
  /**
   * @param {string} src
   */
  const loadScript = function (src) {
    const currentElement = document.createElement('script')
    const firstElement = document.getElementsByTagName('script')[0]

    currentElement.async = true
    currentElement.src = src
    firstElement.parentNode.insertBefore(currentElement, firstElement)
  }

  const hasAnyCounter = metrika || idGTM || idAnalytics

  if (hasAnyCounter) {
    window.trackers = window.trackers || {}

    if (idAnalytics !== null) {
      loadScript(`https://www.googletagmanager.com/gtag/js?id=${idAnalytics}`)
      window.dataLayer = window.dataLayer || []
      // eslint-disable-next-line func-names
      window.gtag = function () {
        // Can't use arrow func + destructuring as Google expects
        // arguments objects in dataLayer (not an array of arguments).
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments)
      }

      window.gtag('js', new Date())
      window.gtag('config', idAnalytics)
    }

    if (idGTM !== null) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      loadScript(`https://www.googletagmanager.com/gtm.js?id=${idGTM}`)
    }

    if (metrika !== null) {
      window.trackers.metrika = metrika.id

      window.ym =
        window.ym ||
        // eslint-disable-next-line func-names
        function () {
          window.ym.a = window.ym.a || []
          // eslint-disable-next-line prefer-rest-params
          window.ym.a.push(arguments)
        }

      window.ym.l = 1 * new Date()

      window.ym(metrika.id, 'init', metrika.init)

      loadScript('https://mc.yandex.ru/metrika/tag.js')
    }

    if (roistatId !== null) {
      window.roistatProjectId = roistatId
      window.roistatHost = 'cloud.roistat.com'
      window.roistatCookieDomain = 'iblschool.ru'

      const protocol =
        document.location.protocol === 'https:' ? 'https://' : 'http://'

      const path = /^.*roistat_visit=[^;]+(.*)?$/.test(document.cookie)
        ? '/dist/module.js'
        : `/api/site/1.0/${roistatId}/init?referrer=${encodeURIComponent(
            document.location.href,
          )}`

      loadScript(`${protocol}cloud.roistat.com${path}`)
    }
  }
}
