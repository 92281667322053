import { isMobileDomain } from 'Helpers/domain'

// Доступные ссылки навигации для клиента, если нет учеников (семейка или заочка)
const familyOrExtramuralEducationAvailableLinks = [
  'client-contracts',
  'client-invoices',
]

// Ссылки навигации для Семейного обучения
const familyEducationLinks = [
  ...familyOrExtramuralEducationAvailableLinks,
  'client-certification',
  'client-calendar',
  'client-goals',
  'client-news',
  'client-events',
  'client-showcase',
  'client-messages',
]

// Доступные ссылки навигации для клиента, если нет учеников (очка)
const fullTimeEducationAvailableLinks = ['client-invoices']

// Ссылки навигации для Очного обучения
const fullTimeEducationLinks = [
  'client-news',
  'client-contracts',
  ...fullTimeEducationAvailableLinks,
  'client-certification',
  'client-statements',
  'client-events',
  'client-schedule-lessons',
  'client-support',
  'client-showcase',
  'client-messages',
]

// Ссылки навигации для Заочного обучения
const extramuralEducationLinks = [
  'client-contracts',
  'client-invoices',
  'client-certification',
  'client-lessons',
  'client-statements',
  'client-calendar',
  'client-goals',
  'client-news',
  'client-events',
  'client-support',
  'client-showcase',
  'client-messages',
]

const linksExcludedOnMobile = [
  'client-contracts',
  'client-invoices',
  'client-events',
]

/**
 * ID школ у которых не будут скрываться
 * ссылки указанные в переменной linksAvailableOnlyOnMainSchools
 */
const mainSchoolsIds = [1, 4, 3]

/**
 * наименования ссылок, которые будут скрываться
 * если клиент или ученик не относится к школам,
 * ID которых указанны в переменной mainSchoolsIds
 */
const linksAvailableOnlyOnMainSchools = ['client-lessons', 'client-events']

export default {
  // Список ссылок бокового меню
  getLinks(state, getters, rootState, rootGetters) {
    let navLinks = state.links
    let hasCertifications = true

    const currentSchoolId = rootState.client.students.school.id
    const educationType = rootGetters['client/students/educationType']
    const fullTimeCertifications =
      rootState.client.fullTimeCertification.certifications
    const familyCertifications =
      rootState.client.familyCertification.certifications
    const extramuralCertifications =
      rootGetters['client/extramuralCertification/getAvailableCertifications']
    const isCurrentStudentDemo =
      rootGetters['client/students/isCurrentStudentDemo']
    const hasEnrolledStudents =
      rootGetters['client/students/hasEnrolledStudents']
    const hasContracts = rootGetters['client/contracts/hasContracts']
    const hasInvoices = rootGetters['client/invoices/hasInvoices']
    const hasModules = rootGetters['common/module/hasModules']
    const isCurrentSchoolIncluded = mainSchoolsIds.includes(currentSchoolId)

    // Если нет счетов
    if (!hasInvoices) {
      navLinks = navLinks.filter((link) => link.link.name !== 'client-invoices')
    }

    // Если ученик с демо-доступом
    if (isCurrentStudentDemo) {
      navLinks = navLinks.filter(
        (link) =>
          ![
            'client-support',
            'client-support-questions',
            'client-support-ideas',
            'client-support-faq',
            'client-support-contacts',
            'client-support-the-question',
            'client-support-the-idea',
          ].includes(link.link.name),
      )
    }

    // Если очный ученик и у клиента нет договоров/черновиков
    if (educationType.fullTime && !hasContracts) {
      navLinks = navLinks.filter(
        (link) => link.link.name !== 'client-contracts',
      )
    }

    if (hasEnrolledStudents) {
      if (educationType.fullTime) {
        navLinks = navLinks.filter((link) =>
          fullTimeEducationLinks.includes(link.link.name),
        )

        hasCertifications = fullTimeCertifications.length > 0
      } else {
        if (educationType.family) {
          navLinks = navLinks.filter((link) =>
            familyEducationLinks.includes(link.link.name),
          )

          hasCertifications = familyCertifications.length > 0
        }

        if (educationType.extramural) {
          navLinks = navLinks.filter((link) =>
            extramuralEducationLinks.includes(link.link.name),
          )

          hasCertifications = extramuralCertifications.length > 0
        }
      }

      if (!hasCertifications) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-certification',
        )
      }

      if (isMobileDomain) {
        navLinks = navLinks.filter(
          (link) => !linksExcludedOnMobile.includes(link.link.name),
        )
      }

      /**
       * Если у клиента школа не Наши пенаты и не Олимп плюс,
       * то мы скрываем в меню кнопки "Уроки" и "События"
       */
      if (!isCurrentSchoolIncluded) {
        navLinks = navLinks.filter(
          (link) => !linksAvailableOnlyOnMainSchools.includes(link.link.name),
        )
      }

      // Если нет доступов к разделу "Сообщения"
      if (!hasModules(['chat'])) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-messages',
        )
      }

      // Если нет доступов к разделу "Поддержка"
      if (!hasModules(['support'])) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-support',
        )
      }

      // Если подключен модуль "Витрина"
      if (hasModules(['educational-showcase'])) {
        navLinks = navLinks.filter((link) => link.link.name !== 'client-events')
      } else {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-showcase',
        )
      }

      return navLinks
    }

    // Если выбранный ученик очный и не зачислен
    if (educationType.fullTime) {
      navLinks = navLinks.filter((link) =>
        fullTimeEducationAvailableLinks.includes(link.link.name),
      )
      // Если выбранный ученик заочный/семейный и не зачислен
    } else {
      if (hasContracts) {
        navLinks = navLinks.filter((link) =>
          familyOrExtramuralEducationAvailableLinks.includes(link.link.name),
        )
      } else {
        navLinks = []
      }
    }

    return navLinks
  },
}
