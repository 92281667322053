export const studentMainRoutes = [
  {
    path: '',
    name: 'student-main',
    component: () =>
      import(
        /* webpackChunkName: "student-main" */
        '@frontend/pages/student/PageMain.vue'
      ),
  },
  {
    path: 'calendar',
    name: 'calendar',
    component: () =>
      import(
        /* webpackChunkName: "calendar" */
        '@frontend/pages/student/Calendar.vue'
      ),
  },
  {
    path: 'calendar/:day([\\d\\-]{10})',
    name: 'calendar-day',
    component: () =>
      import(
        /* webpackChunkName: "client-the-invoice" */
        '@frontend/pages/student/Calendar.vue'
      ),
  },
  {
    path: 'events',
    name: 'student-events',
    component: () =>
      import(
        /* webpackChunkName: "student-events" */
        '@frontend/pages/student/Events.vue'
      ),
  },
  {
    path: 'events/:eventId(\\d+)',
    name: 'student-event',
    component: () =>
      import(
        /* webpackChunkName: "event" */
        '@frontend/pages/student/TheEvent.vue'
      ),
  },
  {
    path: 'goals',
    name: 'goals',
    component: () =>
      import(
        /* webpackChunkName: "goals" */
        '@frontend/pages/student/Goals.vue'
      ),
  },
  {
    path: 'help',
    name: 'help',
    component: () =>
      import(
        /* webpackChunkName: "help" */
        '@frontend/pages/student/Help.vue'
      ),
  },
  {
    path: 'lessons',
    name: 'lessons',
    component: () =>
      import(
        /* webpackChunkName: "lessons" */
        '@frontend/pages/student/Lessons.vue'
      ),
  },
  {
    path: 'lessons/:lessonId(\\d+)',
    name: 'lesson',
    component: () =>
      import(
        /* webpackChunkName: "lesson" */
        '@frontend/pages/student/TheLesson.vue'
      ),
  },
  {
    path: 'news',
    name: 'student-news',
    component: () =>
      import(
        /* webpackChunkName: "student-news" */
        '@frontend/pages/student/News.vue'
      ),
  },
  {
    path: 'news/:newsArticleId(\\d+)',
    name: 'student-news-article',
    component: () =>
      import(
        /* webpackChunkName: "student-news-article" */
        '@frontend/pages/student/TheNewsArticle.vue'
      ),
  },
  {
    path: 'statements',
    name: 'student-statements',
    component: () =>
      import(
        /* webpackChunkName: "student-statements" */
        '@frontend/pages/student/Statements.vue'
      ),
  },
  {
    path: 'statements/:groupId(\\d+)',
    name: 'student-statement',
    component: () =>
      import(
        /* webpackChunkName: "student-statement" */
        '@frontend/pages/student/TheStatement.vue'
      ),
  },
  {
    path: 'certification',
    name: 'certification',
    component: () =>
      import(
        /* webpackChunkName: "student-certification" */
        '@frontend/pages/student/Certification.vue'
      ),
  },
  {
    path: 'notifications',
    name: 'notifications',
    component: () =>
      import(
        /* webpackChunkName: "notifications" */
        '@frontend/pages/common/Notifications.vue'
      ),
  },
  {
    path: 'messages',
    name: 'student-messages',
    component: () =>
      import(
        /* webpackChunkName: "student-messages" */
        '@frontend/pages/common/Messages.vue'
      ),
  },
  {
    path: 'profile',
    name: 'student-profile',
    component: () =>
      import(
        /* webpackChunkName: "student-profile" */
        '@frontend/pages/student/Profile.vue'
      ),
  },
  {
    path: 'schedule-tests',
    name: 'schedule-tests',
    component: () =>
      import(
        /* webpackChunkName: "schedule-tests" */
        '@frontend/pages/student/ScheduleTests.vue'
      ),
  },
  {
    path: 'schedule-lessons',
    name: 'schedule-lessons',
    component: () =>
      import(
        /* webpackChunkName: "schedule-lessons" */
        '@frontend/pages/student/ScheduleLessons.vue'
      ),
  },

  {
    path: 'online-lessons',
    name: 'online-lessons',
    component: () =>
      import(
        /* webpackChunkName: "online-lessons" */
        '@frontend/pages/student/LessonsOnline.vue'
      ),
  },
  {
    path: 'online-lessons/:lessonId(\\d+)',
    name: 'the-online-lesson',
    component: () =>
      import(
        /* webpackChunkName: "online-lessons" */
        '@frontend/pages/student/LessonsOnline.vue'
      ),
  },
]
