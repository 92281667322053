import ApiBase from 'ApiRest/ApiBase'

export default new (class TestingCreate extends ApiBase {
  /**
   * Создать Прохождение Теста для КТП-Теста
   *
   * @param {number} id
   * @returns {Promise}
   */
  post(id) {
    return this._POST(`/account/student/test/ctp-tests/${id}/testings`)
  }
})()
