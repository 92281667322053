import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseSupportInWorkCount = new (class extends ApiBase {
  /**
   *
   * @returns {Promise}
   */
  get() {
    const url = '/showcase/question/in-work-count'

    return this._GET(url)
  }
})()
