/**
 * Кодирует UTF-8 в HEX.
 *
 * @param {string} str
 * @returns {string}
 */
export function toHEX(str) {
  const strEncoded = unescape(encodeURIComponent(str))
  let strEncodedToHex = ''

  for (let i = 0; i < strEncoded.length; i += 1) {
    strEncodedToHex += strEncoded.charCodeAt(i).toString(16)
  }

  return strEncodedToHex
}

/**
 * Декодирует Base64 в UTF-8.
 *
 * @param {string} str
 * @returns {string}
 */
export function fromHEX(str) {
  let strDecodedFromHex = ''

  for (let i = 0; i < str.length; i += 2) {
    strDecodedFromHex += String.fromCharCode(parseInt(str.substr(i, 2), 16))
  }

  return decodeURIComponent(escape(strDecodedFromHex))
}
