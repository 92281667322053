<template>
  <div class="loader-section">
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" key="1">
        <LoaderCircle />
      </div>

      <div v-else key="2" class="w-100 h-100">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import LoaderCircle from '@frontend/ui/LoaderCircle.vue'

export default {
  name: 'ContentWithPreloader',

  components: {
    LoaderCircle,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.loader-section {
  display: flex;
  height: 100%;
  transition: linear 0.3s;
  align-items: center;
  justify-content: center;
}
</style>
