import GuestMain from '@frontend/layouts/GuestMain.vue'

export const guestRoutes = [
  {
    path: '/form/olimp-plus-int',
    component: GuestMain,
    props: () => ({
      schoolName: 'Олимп-плюс',
    }),
    children: [
      {
        path: '',
        name: 'guest-the-form-olymp',
        component: () =>
          import(
            /* webpackChunkName: "guest-the-form-olymp" */
            '@frontend/pages/guest/TheFormFulltimeContract.vue'
          ),
        props: () => ({
          schoolId: 4,
        }),
      },
    ],
  },
]
