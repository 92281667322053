import createActions from './createActions'
import createGetters from './createGetters'
import mutations from './mutations'
import state from './state'

/**
 * @param {'client' | 'student'} user
 * @returns {any}
 */
export default (user) => ({
  namespaced: true,
  state,
  getters: createGetters(user),
  mutations,
  actions: createActions(user),
})
