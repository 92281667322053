import createState from '@frontend/store/guest/theFormFulltimeContract/state'
import { RESET_STATE } from 'Helpers/ResetState'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setGrades(state, payload) {
    state.grades = payload
  },

  setMoscowRegistrationTypes(state, payload) {
    state.childMoscowRegistrationTypes = payload
  },

  setKinshipDegrees(state, payload) {
    state.kinshipDegrees = payload
  },

  setFormData(state, { key, value }) {
    state.formData[key] = value
  },
}
