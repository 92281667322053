import { StatementsFilter } from '@frontend/helpers/StatementsFilter'

export default () => ({
  statements: [],
  theStatement: {
    subject: null,
    periods: [],
  },

  academicYears: [],
  theAcademicYear: null,

  itemsFilter: null,
  statementsFilter: new StatementsFilter(),
})
