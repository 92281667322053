import { WebAnalyticsHelper } from 'Helpers/WebAnalyticsHelper'

export const extramural = {
  /**
   * Клиент в заявке на заочное обучение на шаге "Данные для договора"
   * нажал кнопку "Дальше"
   */
  stepContractDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataContractExtramural()
  },

  /**
   * Клиент в заявке на заочное обучение на шаге "Данные клиента"
   * нажал кнопку "Дальше"
   */
  stepClientDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataClientExtramural()
  },

  /**
   * Клиент в заявке на заочное обучение на шаге "Данные ученика"
   * нажал кнопку "Дальше"
   */
  stepStudentDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataStudentExtramural()
  },
}
