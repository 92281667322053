import { ApiShowcaseCart } from 'ApiRest/Api/Showcase/Cart'
import { ApiShowcaseCartCheckout } from 'ApiRest/Api/Showcase/Cart/Checkout'
import { ApiShoppingCartProducts } from 'ApiRest/Api/Showcase/Cart/Products'
import { ApiShowcaseCartProductsCount } from 'ApiRest/Api/Showcase/Cart/ProductsCount'

export default {
  async fetchProductsCount({ commit }) {
    const { data } = await ApiShowcaseCartProductsCount.get()

    commit('setProductsCount', data)
  },

  async fetchProducts({ commit }) {
    const { data } = await ApiShoppingCartProducts.get()

    commit('setProducts', data)
  },

  async addProduct({ rootState }) {
    const { user, offer } = rootState.common.product

    await ApiShowcaseCart.post({
      offer: {
        id: offer.id,
      },
      consumer: {
        id: user.id,
      },
    })
  },

  async removeProduct(context, product) {
    await ApiShowcaseCart.delete({
      offer: {
        id: product.offer.id,
      },
      consumer: {
        id: product.consumer.id,
      },
    })
  },

  async checkout({ getters, commit }) {
    const { data } = await ApiShowcaseCartCheckout.post(
      getters.checkedProducts.map((product) => ({
        id: product.offer.id,
        consumer: {
          id: product.consumer.id,
        },
      })),
    )

    commit('setInvoices', data.invoices)
  },

  async addProductByLink(context, offerId) {
    await ApiShowcaseCart.post({
      offer: {
        id: offerId,
      },
    })
  },
}
