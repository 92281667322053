// TODO: бекенд должен присылать полностью сформированный текст ошибки

import getErrorsList from '@frontend/helpers/getErrorsList'

/**
 * @param {Object} errorKeyNameList - список читаемых пользователем ключей ({ clientPhone: "Телефон клиента" })
 * @returns {Object}
 */

const getErrorsListNames = (error, errorKeyNameList) => {
  const errorList = error.response?.data ?? {}

  if (errorList) {
    Object.entries(errorList).forEach(([key, value]) => {
      if (errorKeyNameList[key]) {
        const strArr = value.split('')
        const startIndex = strArr.findIndex((i) => i === '«')
        const endIndex = strArr.findIndex((i) => i === '»')

        if (startIndex > 0 && endIndex > 0) {
          errorList[key] = `${value.slice(0, startIndex)}«${
            errorKeyNameList[key]
          }${value.slice(endIndex)}`
        }
      }
    })
  }

  return getErrorsList({
    response: {
      data: {
        errorList,
      },
    },
  })
}

export default getErrorsListNames
