export const previewLessonRoutes = [
  {
    path: '/content/preview/:contentId(\\d+)',
    name: 'the-lesson-preview',
    component: () =>
      import(
        /* webpackChunkName: "the-lesson-preview" */
        '@frontend/pages/student/TheLessonPreview.vue'
      ),
  },
]
