var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      ref: "header",
      staticClass: "s-header-wrapper",
      class: { noticed: _vm.noticeText },
    },
    [
      _vm.noticeText
        ? _c("div", { staticClass: "s-header__notice" }, [
            _c("span", [
              _vm._v("\n      " + _vm._s(_vm.noticeText) + "\n\n      "),
              _c("span", { staticClass: "notice-text--thin" }, [
                _vm._v(_vm._s(_vm.noticeAdditionalMessage)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "s-header" },
        [
          _c(
            "router-link",
            {
              staticClass: "s-logo s-header__logo",
              attrs: { to: _vm.routeMain },
            },
            [
              !_vm.isSchoolEducont
                ? _c("h2", { staticClass: "s-school-name" }, [
                    _vm._v(_vm._s(_vm.schoolName)),
                  ])
                : _c("div", { staticClass: "s-school-name" }, [
                    _c("img", {
                      staticClass: "s-logo-educont",
                      attrs: { src: _vm.logos.educont, alt: "" },
                    }),
                  ]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: _vm.logos.default,
                  alt: _vm.$t("layout.common.logotype"),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "s-header__profile" },
            [
              _c("ElAvatar", {
                class: _vm.abbreviationCssClasses,
                attrs: { abbreviation: _vm.abbreviation, image: _vm.avatar },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.fullName)),
                ]),
                _vm._v(" "),
                _vm.isShownGradeName
                  ? _c("span", { staticClass: "grade" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.gradeName) + "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isShownGradeSelect
                ? _c("v-select", {
                    staticClass: "v-select-student v-select-grade ml-3",
                    attrs: {
                      label: "abbr",
                      placeholder: "Выберите класс",
                      clearable: false,
                      searchable: false,
                      options: _vm.gradeOptions,
                      value: _vm.gradeOptionSelected,
                    },
                    on: { input: _vm.onInputGrade },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "s-header__student" }, [
                                _c("div", { staticClass: "text" }, [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("layout.common.select_class")
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "grade" }, [
                                    _vm._v(_vm._s(option.gradeName)),
                                  ]),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c(
                                "div",
                                { staticClass: "s-header__student-option" },
                                [
                                  _c("b", { staticClass: "name" }, [
                                    _vm._v(_vm._s(option.gradeName)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3373749664
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.rating
            ? _c("TheHeaderRating", { attrs: { rating: _vm.rating } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "nav",
            { staticClass: "s-header__nav" },
            [
              _vm.linkNotifications
                ? _c(
                    "router-link",
                    { attrs: { to: { name: "notifications" } } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.bell,
                          alt: _vm.$t("layout.common.notification_icon"),
                        },
                      }),
                      _vm._v(" "),
                      Number(_vm.linkNotifications.notifications) > 0
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.linkNotifications.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.linkCalendar
                ? _c("router-link", { attrs: { to: { name: "calendar" } } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.icons.calendar,
                        alt: _vm.$t("layout.common.calendar_icon"),
                      },
                    }),
                    _vm._v(" "),
                    Number(_vm.linkCalendar.notifications) > 0
                      ? _c("i", { staticClass: "notif" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.linkCalendar.notifications) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.linkMessages
                ? _c(
                    "router-link",
                    {
                      staticClass: "ml-20",
                      attrs: { to: { name: "student-messages" } },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.message,
                          alt: _vm.$t("layout.common.message_icon"),
                        },
                      }),
                      _vm._v(" "),
                      Number(_vm.linkMessages.notifications) > 0
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.linkMessages.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canAccessProfile
                ? _c(
                    "router-link",
                    { attrs: { to: { name: "student-profile" } } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.settings,
                          alt: _vm.$t("layout.common.settings_icon"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "wide", attrs: { to: { name: "logout" } } },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.icons.exit,
                      alt: _vm.$t("layout.common.exit_icon"),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "s-mobile-menu__toggle",
              class: { opened: _vm.opened },
              on: { click: _vm.toggleMobileMenu },
            },
            _vm._l(3, function (i) {
              return _c("span", { key: i })
            }),
            0
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.opened
              ? _c(
                  "div",
                  {
                    staticClass: "s-mobile-menu",
                    style: _vm.mobileMenuTopOffset,
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "block" },
                      _vm._l(_vm.links, function (link) {
                        return _c(
                          "router-link",
                          {
                            key: link.link.name,
                            staticClass: "link-dark",
                            attrs: { to: link.link },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(link.label) +
                                "\n\n            "
                            ),
                            Number(link.notifications) > 0
                              ? _c("i", { staticClass: "notif" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(link.notifications) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _vm.canAccessProfile
                          ? _c(
                              "router-link",
                              {
                                staticClass: "link-dark",
                                attrs: { to: { name: "student-profile" } },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("layout.common.settings")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "link-dark",
                            attrs: { to: { name: "logout" } },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("layout.common.log_off")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }