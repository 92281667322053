import createState from '@frontend/store/showcase/common/purchases/state'
import { RESET_STATE } from 'Helpers/ResetState'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setPurchases(state, payload) {
    state.purchases = payload
  },

  setPurchase(state, payload) {
    state.purchase = payload
  },

  setPurchaseId(state, payload) {
    state.purchaseId = payload
  },
}
