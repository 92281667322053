import ApiBase from 'ApiRest/ApiBase'

export default new (class ReturnByStudent extends ApiBase {
  /**
   * Отправить сообщение учителю и перевести тестирование в статус "возвращено учеником"
   *
   * @param {number} testingId
   * @param {Object} data - объект { studentComment: 'комментарий ученика' }
   * @returns {Promise}
   */
  put(testingId, data) {
    return this._PUT(
      `/account/student/test/testings/${testingId}/returned-by-student`,
      data,
    )
  }
})()
