import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Создать Прохождение Теста для Очного предварительного теста
   *
   * @param ftePreliminaryTestId
   * @returns {Promise}
   */
  post(ftePreliminaryTestId) {
    return this._POST(
      `/account/student/test/preliminary-tests/${ftePreliminaryTestId}/testings`,
    )
  }
})()
