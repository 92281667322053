import getErrorsList from '@frontend/helpers/getErrorsList'
import UserRouter from '@frontend/UserRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiClientTheInvoice from 'ApiRest/Api/Account/Client/Invoice'
import ApiCancelDiscount from 'ApiRest/Api/Account/Client/Invoice/CancelDiscount'
import ApiClientInvoices from 'ApiRest/Api/Account/Client/Invoice/List'
import ApiPaymentUrl from 'ApiRest/Api/Account/Client/Invoice/PaymentUrl'
import Vue from 'vue'

import { i18n } from '@/i18n/setup'
import { eventBus } from '@/pubsub/bus'

const globalMessageOnApplyPromocode = function () {
  eventBus.$emit('frontend.client.promocode.applied')
}

export default {
  /**
   * Список счетов клиента
   *
   * @param {{commit}} context
   * @returns {Promise<void>}
   */
  async fetchInvoices({ commit }) {
    const params = {
      showcase: 0,
    }

    const { data } = await ApiClientInvoices.get(params)

    commit('setInvoices', data)
  },

  /**
   * Счет клиента
   *
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoice({ commit }, invoiceId) {
    try {
      const { data } = await ApiClientTheInvoice.get(invoiceId)

      commit('setTheInvoice', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        Vue.toasted.show(
          i18n.t('store.client.toasted_message.account_not_found'),
          {
            type: 'error',
            duration: 3000,
          },
        )

        UserRouter.router.push({
          name: 'client-invoices',
        })
      }

      throw error
    }
  },

  /**
   * Ссылка на url счета
   *
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoicePaymentUrl({ commit }, invoiceId) {
    try {
      const { data } = await ApiPaymentUrl.get(invoiceId)

      commit('setTheInvoicePaymentUrl', data)
    } catch (error) {
      Vue.toasted.show(i18n.t('store.client.toasted_message.unknown_error'), {
        type: 'error',
        duration: 3000,
      })

      throw error
    }
  },

  /**
   * Применить промокод
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.invoiceId
   * @param {Object} payload.data
   * @returns {Promise<void>}
   */
  async applyPromocode(context, { invoiceId, data }) {
    try {
      await ApiClientTheInvoice.put(invoiceId, data)

      Vue.toasted.show(
        i18n.t('store.client.toasted_message.promo_code_successfully_applied'),
        {
          type: 'success',
        },
      )

      globalMessageOnApplyPromocode()
    } catch (error) {
      const status = error?.response?.status

      if (status === HttpStatusCodes.UnprocessableEntity) {
        Vue.toasted.show(getErrorsList(error), {
          type: 'error',
        })
      }

      throw error
    }
  },

  /**
   * Отменить промокод
   *
   * @param {{}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async discardPromocode(context, invoiceId) {
    await ApiCancelDiscount.put(invoiceId)

    Vue.toasted.show(
      i18n.t('store.client.toasted_message.promo_code_deleted'),
      {
        type: 'success',
      },
    )
  },
}
