import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiCalendarItems from 'ApiRest/Api/CalendarItems'

export default {
  /**
   * @param {Function} commit
   * @param {Object} payload
   * @param {string} payload.start
   * @param {string|null} payload.end
   * @returns {Promise}
   */
  async fetchItems({ commit }, { start, end = null }) {
    const { data, request } = await ApiCalendarItems.get({
      start,
      end,
      onlyMineLessons: true,
    })

    commit('setItems', request.status === HttpStatusCodes.NoContent ? [] : data)
  },
}
