import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiShowcaseProductOffer = new (class extends ApiBase {
  /**
   * @param {number} productId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(productId, parameters = {}) {
    const urlParams = UrlHelper.param(parameters)
    const url = `/showcase/product/${productId}/offers?${urlParams}`

    return this._GET(url)
  }
})()
