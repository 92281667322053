import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import { mainSchool } from 'Helpers/const/AppData'
import { insertTrackersInDOM } from 'Helpers/trackers'
import schoolScripts from 'Helpers/trackers/scripts'

const getSchoolId = async () => {
  const domain = window.domains.main
  let { id } = mainSchool

  try {
    const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

    if (subdomainsList.length) {
      const school = subdomainsList.find((subdomain) => subdomain.self)

      if (school) {
        id = school.schoolId
      }
    }

    return id
  } catch (error) {
    return null
  }
}

export const initTrackers = async () => {
  const shouldLoadTrackers = process.iblsEnv.NODE_ENV === 'production'

  if (shouldLoadTrackers) {
    const schoolId = await getSchoolId()
    const school = schoolScripts[schoolId]

    if (school && Object.keys(school).length > 0) {
      insertTrackersInDOM(school)
    }
  }
}
