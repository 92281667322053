import { isNil } from 'lodash'

/**
 * @typedef {Object} statementsFilterOption
 * @property {string} name - наименование
 * @property {number} id - идентификатор
 */

/**
 * @param {boolean} isClient - Параметр для определения name в варианте актуальных предметов
 */
export class StatementsFilter {
  /**
   * @param isClient
   */
  constructor(isClient = false) {
    /**
     * @type {statementsFilterOption[]}
     */
    this.options = [
      {
        id: 1,
        name: isClient ? 'Предметы ученика' : 'Мои предметы',
      },
      {
        id: 2,
        name: 'Все оценки в этом году',
      },
    ]
  }

  /**
   *
   * @param {statementsFilterOption|null} value
   * @returns {boolean}
   */
  isActualSubjects(value) {
    if (isNil(value)) {
      return false
    }

    return this.actualSubjects.id === value.id
  }

  /**
   *
   * @param {statementsFilterOption|null} value
   * @returns {boolean}
   */
  isAllSubjectsWithMarks(value) {
    if (isNil(value)) {
      return false
    }

    return this.allSubjectsWithMarks.id === value?.id
  }

  get actualSubjects() {
    return this.options[0]
  }

  get allSubjectsWithMarks() {
    return this.options[1]
  }
}
