import UserRouterShowcase from '@frontend/UserRouterShowcase'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiLesson from 'ApiRest/Api/Account/Student/Lesson'
import ApiContentNotPass from 'ApiRest/Api/Account/Student/Lesson/ContentNotPass'
import ApiContentPass from 'ApiRest/Api/Account/Student/Lesson/ContentPass'
import ApiFamilyListPreview from 'ApiRest/Api/Account/Student/Lesson/FamilyListPreview'
import ApiLessonList from 'ApiRest/Api/Account/Student/Lesson/List'
import { ApiEducontLessonFeedback } from 'ApiRest/Api/Educont/Lesson/Feedback'
import { ApiShowcaseSupportAskQuestionContent } from 'ApiRest/Api/Showcase/AskQuestion/Content'
import Vue from 'vue'

import { i18n } from '@/i18n/setup'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async fetchLessons({ commit }) {
    const { data } = await ApiLessonList.get()

    commit('setLessons', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async fetchLessonsPreview({ commit }) {
    const { data } = await ApiFamilyListPreview.get()

    commit('setLessonsPreview', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} lessonId
   * @returns {Promise<*>}
   */
  async fetchTheLesson({ commit }, lessonId) {
    try {
      const { data } = await ApiLesson.get(lessonId)

      commit('setTheLesson', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        Vue.toasted.show(
          i18n.t('store.student.toasted_message.lesson_was_not_found'),
          {
            type: 'error',
          },
        )

        UserRouterShowcase.router.push({
          name: 'not-found',
        })
      }

      throw error
    }
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {number} payload.lessonId
   * @param {number} payload.contentId
   * @param {boolean} payload.isPassed
   * @returns {Promise<void>}
   */
  async updateTheContentIsPassed(
    { commit },
    { lessonId, contentId, isPassed },
  ) {
    const apiMethod = isPassed ? ApiContentPass : ApiContentNotPass

    await apiMethod.put(lessonId, contentId)
    commit('setTheContentIsPassed', {
      contentId,
      isPassed,
    })
  },

  /**
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.contentId
   * @param {string} payload.question
   * @param {number} payload.lessonId
   * @returns {Promise<void>}
   */
  async askQuestion(context, { contentId, question, lessonId }) {
    const data = {
      content: {
        id: contentId,
      },
      lesson: {
        id: lessonId,
      },
      questionBody: question,
    }

    await ApiShowcaseSupportAskQuestionContent.post(contentId, data)
  },

  async sendEducontFeedback(context, { ctpId, contentId, message }) {
    await ApiEducontLessonFeedback.post(ctpId, contentId, message)
  },
}
