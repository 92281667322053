import ApiBase from 'ApiRest/ApiBase'

export const ApiShoppingCartProducts = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = '/cart'

    return this._GET(url)
  }
})()
