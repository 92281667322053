import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchase = new (class extends ApiBase {
  /**
   * @param {number} purchaseId
   * @returns {Promise}
   */
  get(purchaseId) {
    const url = `/purchase/${purchaseId}`

    return this._GET(url)
  }
})()
