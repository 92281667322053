import storage from '@frontend/helpers/storage'
import UserRouter from '@frontend/UserRouter'
import ApiSchool from 'ApiRest/Api/Account/Client/School'
import ApiActiveSubjectList from 'ApiRest/Api/Account/Client/Students/ActiveSubject/List'
import ApiStudentAnalytics from 'ApiRest/Api/Account/Client/Students/Analytics'
import { ApiAcountClientStudentsExpelled } from 'ApiRest/Api/Account/Client/Students/Expelled'
import ApiStudentList from 'ApiRest/Api/Account/Client/Students/List'
import Constants from 'Constants'

const isRoot = {
  root: true,
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  resetStatesOnStudentChange({ commit }) {
    commit('client/news/resetState', null, isRoot)
    commit('client/familyCertification/resetState', null, isRoot)
    commit('client/familyCertificationRequest/resetState', null, isRoot)
    commit('client/extramuralCertification/resetState', null, isRoot)
    commit('client/journal/resetState', null, isRoot)
    commit('setAnalytics', {})
    commit('setAnalyticsAcademicYear', null)
  },

  fetchStudents({ state, commit, dispatch, getters }) {
    return ApiStudentList.get().then(async ({ data }) => {
      if (data.length) {
        commit('setStudents', data)

        const savedStudentId = storage.get('clientCurrentStudent')
        const savedStudent = state.students.find(
          ({ id }) => Number(id) === Number(savedStudentId),
        )
        const isSavedStudentEnrolled =
          savedStudent?.accountStatus.id === Constants.accountStatus.ACTIVE &&
          savedStudent?.enrollmentStatus.id ===
            Constants.enrollmentStatus.ENROLLED

        if (savedStudent && isSavedStudentEnrolled) {
          await dispatch('fetchTheStudent', savedStudentId)
        } else {
          if (getters.hasEnrolledStudents) {
            await dispatch('fetchTheStudent', getters.enrolledStudents[0].id)
          }
        }
      }
    })
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} parameters
   */
  async fetchAnalytics({ commit }, parameters) {
    const { data } = await ApiStudentAnalytics.get(parameters)

    commit('setAnalytics', data)
  },

  fetchSchool({ commit }, schoolId) {
    return ApiSchool.get(schoolId).then(({ data }) => {
      commit('setSchool', data)
    })
  },

  async fetchTheStudent(
    { state, commit, dispatch, rootState, rootGetters },
    studentId,
  ) {
    storage.set('clientCurrentStudent', studentId)

    commit('setTheStudent', studentId)

    const schoolId = state.currentStudent.school?.id
    const isExtramural = rootGetters['client/students/educationType'].extramural
    const isFamily = rootGetters['client/students/educationType'].family
    const isFullTime = rootGetters['client/students/educationType'].fullTime

    await dispatch('client/students/resetStatesOnStudentChange', null, isRoot)

    const promises = [
      dispatch('fetchSchool', schoolId),
      dispatch('fetchActiveSubjects', studentId),
    ]

    if (UserRouter.router.currentRoute.name !== 'client-contracts') {
      promises.push(
        dispatch('client/contracts/fetchContracts', null, isRoot),
        dispatch('client/contracts/fetchDrafts', null, isRoot),
      )
    }

    if (isExtramural || isFamily) {
      await dispatch('client/statements/fetchPeriodList', null, isRoot)

      commit(
        'client/statements/setPeriod',
        rootGetters['client/students/currentStudentStreamAcademicYearPreset'],
        isRoot,
      )

      if (rootState.client.statements.period !== null) {
        const statementData = {
          academicYearPreset: {
            id: rootState.client.statements.period.id,
          },
          student: {
            id: studentId,
          },
        }

        promises.push(
          dispatch('client/statements/fetchStatements', statementData, isRoot),
        )
      }

      if (UserRouter.router.currentRoute.name !== 'client-goals') {
        promises.push(dispatch('client/goals/fetchGoals', studentId, isRoot))
      }

      if (UserRouter.router.currentRoute.name !== 'client-lessons') {
        promises.push(
          dispatch('client/lessons/fetchLessons', studentId, isRoot),
        )
      }
    }

    if (isExtramural) {
      const certificationData = {
        student: {
          id: studentId,
        },
      }

      if (UserRouter.router.currentRoute.name !== 'client-certification') {
        promises.push(
          dispatch(
            'client/extramuralCertification/fetchCertifications',
            certificationData,
            isRoot,
          ),
        )
      }
    }

    if (isFamily) {
      promises.push(
        dispatch(
          'client/familyCertificationRequest/fetchTheFamilyCertificationRequestIsAvailable',
          studentId,
          isRoot,
        ),
      )

      if (UserRouter.router.currentRoute.name !== 'client-certification') {
        promises.push(
          dispatch(
            'client/familyCertification/fetchCertifications',
            studentId,
            isRoot,
          ),
        )
      }
    }

    if (isFullTime) {
      await dispatch(
        'client/fullTimeStatement/fetchAcademicYears',
        studentId,
        isRoot,
      )

      const { academicYears } = rootState.client.fullTimeStatement
      const certificationData = {
        student: {
          id: studentId,
        },
      }

      if (UserRouter.router.currentRoute.name !== 'client-certification') {
        promises.push(
          dispatch(
            'client/fullTimeCertification/fetchCertifications',
            certificationData,
            isRoot,
          ),
        )
      }

      if (academicYears?.length > 0) {
        commit(
          'client/fullTimeStatement/setTheAcademicYear',
          rootGetters['client/students/currentStudentStreamAcademicYearPreset'],
          isRoot,
        )

        const fullTimeStatementData = {
          studentId,
          params: {
            academicYearPreset: {
              id: rootState.client.fullTimeStatement.theAcademicYear?.id,
            },
          },
        }

        const averageMarkData = {
          studentId,
          params: {
            academicYearPreset: {
              id: rootState.client.fullTimeStatement.theAcademicYear?.id,
            },
          },
        }

        promises.push(
          dispatch(
            'client/fullTimeStatement/fetchFullTimeCtpStatements',
            fullTimeStatementData,
            isRoot,
          ),
          dispatch('client/homework/homeworkDatesFetch', studentId, isRoot),
          dispatch(
            'client/averageMark/fetchAverageMarksMonthly',
            averageMarkData,
            isRoot,
          ),
        )
      }
    }

    // THE_FAMILY_CERTIFICATION_REQUEST_IS_AVAILABLE_FETCH
    // эндпоинт, вызываемый экшном
    // возвращает 404, если Аттестация не доступна
    await Promise.allSettled(promises)

    if (isFullTime) {
      dispatch('client/averageMark/prepareChartData', null, isRoot)
    }
  },

  async fetchActiveSubjects({ commit }, studentId) {
    const { data } = await ApiActiveSubjectList.get(studentId)

    commit('setActiveSubjects', data)
  },

  excludeStudent(payload, studentId) {
    return ApiAcountClientStudentsExpelled.put(studentId)
  },
}
