import UserRouter from '@frontend/UserRouter'
import HttpHeaders from 'Api/const/HttpHeaders'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiEvent from 'Api/rest/Api/Account/Client/Students/Event'
import ApiEventList from 'Api/rest/Api/Account/Client/Students/Event/List'
import Vue from 'vue'

import { i18n } from '@/i18n/setup'

export default {
  async fetchEvents({ commit }, { studentId, params }) {
    const { data, headers } = await ApiEventList.get(studentId, params)

    if (params?.page === 1) {
      commit('setEvents', data)
    } else {
      commit('addEvents', data)
    }

    const pagination = {
      currentPage: headers[HttpHeaders.PAGINATION_PAGE],
      pagesTotal: headers[HttpHeaders.PAGINATION_PAGES_TOTAL],
    }

    commit('setPagination', pagination)
  },

  async fetchTheEvent({ commit }, { studentId, eventId }) {
    // TODO: -> component
    try {
      const { data } = await ApiEvent.get(studentId, eventId)

      commit('setTheEvent', data)
    } catch (error) {
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        const message = i18n.t('store.client.toasted_message.event_not_found')

        Vue.toasted.show(message, {
          type: 'error',
          duration: 5000,
        })

        await UserRouter.router.push({
          name: 'client-main',
        })
      }

      throw error
    }
  },
}
