import ApiBase from 'ApiRest/ApiBase'

export default new (class Login extends ApiBase {
  /**
   * @param data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/auth/authorization', data)
  }

  /**
   * @returns {Promise}
   */
  delete() {
    return this._DELETE('/auth/authorization')
  }
})()
