export const studentTestRoutes = [
  {
    path: '/student/test/ctp/:ctpTestId(\\d+)',
    name: 'test-hello-ctp',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/student/test/TestHello.vue'
      ),
  },
  {
    path: '/student/test/cert/:certTestId(\\d+)',
    name: 'test-hello-cert',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/student/test/TestHello.vue'
      ),
  },
  {
    path: '/student/test/fe-cert/:feCertTestId(\\d+)',
    name: 'test-hello-fe-cert',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/student/test/TestHello.vue'
      ),
  },
  {
    path: '/student/test/fte-ctp/:fteCtpTestId(\\d+)',
    name: 'test-hello-fte-ctp',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/student/test/TestHello.vue'
      ),
  },
  {
    path: '/student/test/fte-preliminary/:ftePreliminaryTestId(\\d+)',
    name: 'test-hello-fte-preliminary',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/student/test/TestHello.vue'
      ),
  },
  {
    path: '/student/test/fte-final/:fteFinalTestId(\\d+)',
    name: 'test-hello-fte-final',
    component: () =>
      import(
        /* webpackChunkName: "test-hello" */
        '@frontend/pages/student/test/TestHello.vue'
      ),
  },
  {
    path: '/student/test/ctp/:ctpTestId(\\d+)/start/:attemptId(\\d+)?',
    name: 'test-start-ctp',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/student/test/TestStart.vue'
      ),
  },
  {
    path: '/student/test/cert/:certTestId(\\d+)/start',
    name: 'test-start-cert',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/student/test/TestStart.vue'
      ),
  },
  {
    path: '/student/test/fe-cert/:feCertTestId(\\d+)/start',
    name: 'test-start-fe-cert',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/student/test/TestStart.vue'
      ),
  },
  {
    path: '/student/test/fte-ctp/:fteCtpTestId(\\d+)/start',
    name: 'test-start-fte-ctp',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/student/test/TestStart.vue'
      ),
  },
  {
    path: '/student/test/fte-preliminary/:ftePreliminaryTestId(\\d+)/start',
    name: 'test-start-fte-preliminary',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/student/test/TestStart.vue'
      ),
  },
  {
    path: '/student/test/fte-final/:fteFinalTestId(\\d+)/start',
    name: 'test-start-fte-final',
    component: () =>
      import(
        /* webpackChunkName: "test-start" */
        '@frontend/pages/student/test/TestStart.vue'
      ),
  },
]
