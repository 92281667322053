import ApiTheFullTimeCtpStatement from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement'
import ApiAcademicYears from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/AcademicYear/List'
import ApiFullTimeCtpStatements from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/List'

export default {
  async fetchFullTimeCtpStatements({ commit }, { studentId, params }) {
    const { data } = await ApiFullTimeCtpStatements.get(studentId, params)

    commit('setFullTimeCtpStatements', data)
  },

  async fetchTheFullTimeCtpStatement({ commit }, { studentId, groupId }) {
    const parameters = {
      statementTests: true,
    }

    const { data } = await ApiTheFullTimeCtpStatement.get(
      studentId,
      groupId,
      parameters,
    )

    commit('setTheFullTimeCtpStatement', data)
  },

  async fetchAcademicYears({ commit }, studentId) {
    const { data } = await ApiAcademicYears.get(studentId)

    commit('setAcademicYears', data)
  },
}
