import ApiHomeworkSolution from 'ApiRest/Api/Account/Student/Ctp/HomeworkSolution'
import ApiChecked from 'ApiRest/Api/Account/Student/Ctp/HomeworkSolution/Checked'
import ApiHomeworkSolutions from 'ApiRest/Api/Account/Student/Ctp/HomeworkSolution/List'
import ApiUnChecked from 'ApiRest/Api/Account/Student/Ctp/HomeworkSolution/Unchecked'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async homeworkDatesFetch({ commit }) {
    const { data } = await ApiHomeworkSolutions.get()

    commit('homeworkDatesSet', data)
  },

  theHomeworkSolutionUpdate(context, solution) {
    return ApiHomeworkSolution.put(solution.id, solution)
  },

  theHomeworkTaskCheckedUpdate(context, { checked, taskId }) {
    if (checked) {
      return ApiChecked.put(taskId)
    }

    return ApiUnChecked.put(taskId)
  },
}
